import { Tooltip } from "@mui/material";
import { HTMLAttributeAnchorTarget } from "react";
import { Link } from "react-router-dom";

interface IProps {
    tooltip?: string
    label: string;
    url: string;
    target?: HTMLAttributeAnchorTarget;
    rel?: string;
}
const LinkNav = (props: IProps) => {
    const { tooltip, label, url, target = '_blank', rel } = props;

    return (
        <Tooltip title={tooltip} placement={'top'}>
            <Link
                to={url}
                target={target}
                rel={rel}
                style={{ color: 'inherit' }}
            >
                {label}
            </Link>
        </Tooltip>
    );
}
export default LinkNav;