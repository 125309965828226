import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { removeEmptyFields } from "../../../../helpers/generalHelper";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { IIdValue } from "../../../../models/CommonModels";
import { IDocument } from "../../../../models/DocumentModels";
import { EUserDocumentType, IUserDocumentRequestDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";
import { RootState } from "../../../../store/store";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DateField from "../../../Base/DateComponent/DateField";
import DocumentTypeSelect from "../../../Base/DocumentTypeSelectComponent/DocumentTypeSelect";
import CountryAutocomplete from "../../../Base/GeolocationComponent/CountryAutocomplete";
import StateAutocomplete from "../../../Base/GeolocationComponent/StateAutocomplete";

const countryFiled: EUserDocumentType[] = [EUserDocumentType.PASSPORT, EUserDocumentType.DRIVER_LICENSE, EUserDocumentType.DOCUMENT];
const issueDateField: EUserDocumentType[] = [EUserDocumentType.PASSPORT, EUserDocumentType.DOCUMENT];

const defaultCountry: string = 'UNITED STATES OF AMERICA';

const documentTypeData: string[] = Object.keys(EUserDocumentType)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== EUserDocumentType[EUserDocumentType.NONE])
    .map(key => key.toString())

interface IProps {
    userId: string;
    document?: IDocument;
    open: boolean;

    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const UserDocumentDialog = (props: IProps) => {
    const { open, userId, document, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'user-document-form';
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const [loading, setLoading] = useState(false);
    const [maxDate, setMaxDate] = useState<number | undefined>(document ? document?.dateOfIssue : undefined);
    const [minDate, setMinDate] = useState<number | undefined>(document ? document?.expirationDate : undefined);
    const [country, setCountry] = useState<IIdValue>();

    const { register, setError, clearErrors, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IUserDocumentRequestDto>({
        defaultValues: {
            type: document ? document!.type as EUserDocumentType : undefined,
            expirationDate: document ? document?.expirationDate : NaN,
            number: document ? document?.number : undefined,
            dateOfIssue: document ? document?.dateOfIssue : NaN,
            issueCountryId: document ? document?.issueCountry?.uuid : undefined,
            issueStateId: document ? document?.issueState?.uuid : undefined,
            caption: document ? document?.caption : undefined
        }
    });

    const updateData = useCallback((uuid: string, data: IUserDocumentRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.updateDocument(userId, uuid, data);
            if (response) {
                displayNotification({ message: t('Document was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onCloseBtnClick, onSubmitBtnClick, t, userId]);

    const createData = useCallback((data: IUserDocumentRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.createDocument(userId, data);
            if (response) {
                displayNotification({ message: t('Document was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onCloseBtnClick, onSubmitBtnClick, t, userId]);

    const onSubmit = useCallback((data: IUserDocumentRequestDto) => {
        const normalisedData: IUserDocumentRequestDto = removeEmptyFields(data) as unknown as IUserDocumentRequestDto;
        if (document) {
            updateData(document.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, document, updateData]);

    const validateType = useCallback((value: EUserDocumentType) => {
        return EUserDocumentType[value] !== undefined && value !== EUserDocumentType.NONE;
    }, []);

    register('type', { validate: validateType });
    const onDocumentTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as EUserDocumentType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateNumber = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('number', { message: message });
            return false;
        }
        clearErrors('number');
        return true;
    }, [clearErrors, setError, t]);

    const onInitIssueCountryHandler = useCallback((value: IIdValue | null) => {
        setValue('issueCountryId', value?.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
        setCountry(value || undefined)
    }, [setValue]);

    register('issueCountryId');
    const onChangeIssueCountryHandler = useCallback((value: IIdValue | null) => {
        setValue('issueCountryId', value?.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });

        setCountry(value || undefined)

        if (value?.name.toLocaleLowerCase() !== defaultCountry.toLocaleLowerCase()) {
            setValue('issueStateId', undefined, { shouldValidate: true, shouldDirty: true });
        }
    }, [setValue]);

    register('issueStateId');
    const onChangeIssueStateHandler = useCallback((value: IIdValue | null) => {
        setValue('issueStateId', value?.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('dateOfIssue');
    const onChangeDateOfIssueHandler = useCallback((timestamp?: number) => {
        setValue('dateOfIssue', timestamp, { shouldDirty: true });
        setMinDate(timestamp);
    }, [setValue]);

    const validateExpirationDate = useCallback((timestamp?: number) => {
        return getValues('type') === EUserDocumentType.DRUG_TEST && !timestamp ? false : true;
    }, [getValues]);

    register('expirationDate', { validate: validateExpirationDate });
    const onChangeExpirationDateHandler = useCallback((timestamp?: number) => {
        setValue('expirationDate', timestamp, {
            shouldValidate: true,
            shouldDirty: true
        });
        setMaxDate(timestamp);
    }, [setValue]);

    const validateCaption = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('caption', { message: message });
            return false;
        }
        clearErrors('caption');
        return true;
    }, [clearErrors, setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <DocumentTypeSelect
                        label={t('TYPE')}
                        size='medium'
                        data={documentTypeData}
                        required
                        value={getValues('type')}
                        onChange={onDocumentTypeChangeHandler}
                    />

                    <TextField
                        {...register('number', { validate: validateNumber })}
                        autoComplete={'off'}
                        label={t('NUMBER')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.number}
                        helperText={errors.number?.message}
                    />

                    <TextField
                        {...register('caption', { validate: validateCaption })}
                        autoComplete={'off'}
                        label={t('NOTES')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.caption}
                        helperText={errors.caption?.message}
                    />

                    {getValues('type') && countryFiled.includes(getValues('type')) &&
                        <CountryAutocomplete
                            label={t('COUNTRY')}
                            onInit={onInitIssueCountryHandler}
                            onChange={onChangeIssueCountryHandler}
                            defaultValue={getValues('type') !== EUserDocumentType.DOCUMENT ? defaultCountry : undefined}
                            value={getValues('issueCountryId')}
                        />
                    }

                    {getValues('type') && countryFiled.includes(getValues('type')) && defaultCountry.toLocaleLowerCase() === country?.name.toLocaleLowerCase() &&
                        <StateAutocomplete
                            label={t('STATE')}
                            onChange={onChangeIssueStateHandler}
                            value={getValues('issueStateId')}
                        />
                    }

                    <Stack spacing={2} direction='row'>
                        {getValues('type') && issueDateField.includes(getValues('type')) &&
                            <DateField
                                label={t('DATE OF ISSUE')}
                                size='medium'
                                value={getValues('dateOfIssue')}
                                format={dateFormat}
                                onChange={onChangeDateOfIssueHandler}
                                max={maxDate}
                            />
                        }

                        <DateField
                            required={getValues('type') === EUserDocumentType.DRUG_TEST}
                            label={t('EXPIRATION DATE')}
                            size='medium'
                            value={getValues('expirationDate')}
                            format={dateFormat}
                            onChange={onChangeExpirationDateHandler}
                            min={minDate}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        errors.caption, errors.number, getValues, handleSubmit, maxDate, minDate, dateFormat,
        onChangeDateOfIssueHandler, onChangeExpirationDateHandler, onChangeIssueCountryHandler,
        onDocumentTypeChangeHandler, onSubmit, register, t, validateCaption, validateNumber,
        onChangeIssueStateHandler, country?.name, onInitIssueCountryHandler
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${document ? 'EDIT' : 'CREATE'} DOCUMENT`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    )
}
export default UserDocumentDialog;