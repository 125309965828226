import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { generateNumber } from "../../../helpers/generalHelper";
import BaseDialog from "../BaseDialogComponent/BaseDialog";

const defaultMessage: string = 'To confirm the action, enter the red text in the field below:'

export interface IProps {
    open: boolean;
    message?: string;
    onConfirm: () => void;
    onClose: () => void;
}
const ButtonConfirmationDialog = (props: IProps) => {
    const { open, message = defaultMessage, onConfirm, onClose } = props;
    const { t } = useTranslation();

    const [code] = useState<string>(generateNumber(100, 9999).toString());
    const [value, setValue] = useState<string>('');

    const onValueChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setValue(value);
    }, []);

    const onConfirmHandler = useCallback(() => {
        if (value === code) {
            onConfirm();
            onClose();
        }
    }, [code, onClose, onConfirm, value]);

    const onBuildContent = useCallback(() => {
        return (
            <Box>
                <Typography>
                    {t(message)}&nbsp;<b style={{ color: 'red' }}>{code}</b>
                </Typography>
                <TextField
                    autoFocus
                    required
                    margin='dense'
                    fullWidth
                    variant='standard'
                    value={value}
                    onChange={onValueChangeHandler}
                />
            </Box>
        );
    }, [code, message, onValueChangeHandler, t, value]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('CONFIRM ACTION')} arrow>
                <span>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<DoneIcon />}
                        disabled={value !== code}
                        onClick={onConfirmHandler}
                    >
                        {t('CONFIRM')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [code, onConfirmHandler, t, value]);

    return (
        <BaseDialog
            open={open}
            title={t('CONFIRM ACTION')}
            maxWidth={'xs'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onClose}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default ButtonConfirmationDialog;