import { IAddressShortResponseDto } from "./AddressModels";
import { EFragilityLevel, IDateAndTime, IIdValue } from "./CommonModels";
import { ICustomerIdNameResponseDto } from "./CustomerModels";
import { IDocument } from "./DocumentModels";
import { IFileDetailsResponseDto } from "./FileModel";
import { EVehicleType, ITransportShortResponseDto } from "./TransportModels";
import { IUserShortResponseDto } from "./UserModels";

export enum ELoadDocumentType {
  NONE,
  DOCUMENT = 'DOCUMENT',
  DELIVERY_CONFIRMATION = 'DELIVERY_CONFIRMATION',
  BILL_OF_LADING = 'BILL_OF_LADING',
  RATE_CONFIRMATION = 'RATE_CONFIRMATION',
  CHARGE = 'CHARGE',
  INVOICE = 'INVOICE'
}

export enum ELoadStatus {
  NONE,
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DELIVERED = 'DELIVERED',
  INVOICED = 'INVOICED',
  UNPAID = 'UNPAID',
  PAID = 'PAID'
}

export enum ELoadPayType {
  NONE,
  DEDUCTIONS_ADVANCES = 'DEDUCTIONS_ADVANCES',
  ADDITIONAL = 'ADDITIONAL'
}

export enum ELoadPayStatus {
  NONE,
  OPEN = 'OPEN',
  PAID = 'PAID'
}

export enum ELoadShipperConsigneeStatus {
  NONE,
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum ELoadAppointmentType {
  NONE,
  YES = 'YES',
  NO = 'NO',
  FCFS = 'FCFS'
}

export enum ELoadItemDirection {
  SHIPPER = 'SHIPPER',
  CONSIGNEE = 'CONSIGNEE'
}

export enum ELoadItemType {
  NONE,
  TL = 'TL',
  LTL = 'LTL'
}

export interface ILoadCoverFaceResponseDto {
  uuid: string;
  idno?: string;
  assignLater: boolean;
  customer: ICustomerIdNameResponseDto;
  rate: number;
  flatRate?: number;
  charges: number;
  chargesRequiresProofs: boolean;
  totalRate: number;
  totalShipperItems: number;
  totalConsigneeItems: number;
  shipperWeight: number;
  consigneeWeight: number;
  driver?: IUserShortResponseDto;
  status: ELoadStatus;
  readonly: boolean;
  favorite: boolean;
}

export interface ILoadResponseDto {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  assignLater: boolean;
  idno?: string;
  rate: number;
  flatRate?: number;
  charges: number;
  chargesRequiresProofs: boolean;
  totalRate: number;
  totalShipperItems: number;
  totalConsigneeItems: number;
  shipperWeight: number;
  consigneeWeight: number;
  shipDates?: IDateAndTime[];
  delDates?: IDateAndTime[];
  pickupAddresses?: string[];
  deliveryAddresses?: string[];
  driver?: IUserShortResponseDto;
  dispatcher?: IUserShortResponseDto;
  administrator?: IUserShortResponseDto;
  transports?: ITransportShortResponseDto[];
  status: ELoadStatus;
  statusDate: number;
  favorite: boolean;
  numberOfOpenIssues?: number;
  numberOfInProgressIssues?: number;
  numberOfDoneIssues?: number;
  numberOfUnresolvedIssues?: number;
  invoice?: IIdValue;
  createdBy: string;
  createdDate: number;
  updatedBy?: string;
  updatedDate?: number;
}

export interface ILoadShortResponseDto {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  idno?: string;
  status: ELoadStatus;
}

export interface ILoadAutocompleteResponseDto {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  idno?: string;
  rate: number;
  flatRate?: number;
  status: ELoadStatus;
}

export interface ILoadDocumentRequestDto {
  type: ELoadDocumentType;
  expirationDate?: number;
  number?: string;
  caption?: string;
}

export interface ILoadRequestDto {
  customerId: string;
  assignLater: boolean;
  idno?: string;
  rate: number;
  flatRate?: number;
}

export interface ILoadOverviewResponseDto {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  assignLater: boolean;
  idno?: string;
  rate: number;
  flatRate?: number;
}

export interface IPalletTypeResponseDto {
  uuid: string;
  name: string;
  height: number;
  width: number;
  length: number;
  weight?: number;
  allowDelete?: boolean;
}

export interface IPalletTypeAutocompleteResponseDto {
  uuid: string;
  name: string;
  height: number;
  width: number;
  length: number;
  weight?: number;
}

export interface IPalletTypeRequestDto {
  name: string;
  height: number;
  width: number;
  length: number;
  weight?: number;
}

export interface IPalletTypeQuantity {
  uuid: string;
  pallet: IPalletTypeResponseDto;
  quantity: number;
}

export interface IPalletTypeQuantityRequestDto {
  palletTypeId: string;
  quantity: number;
}

export interface ILoadShipperConsigneeIdNameAddressResponseDto {
  uuid: string;
  name: string;
  address: IAddressShortResponseDto;
}

export interface ILoadItemResponseDto {
  uuid: string;
  direction: ELoadItemDirection;
  shipperConsignee: ILoadShipperConsigneeIdNameAddressResponseDto;
  type: ELoadItemType;
  notes?: string;
  highValue: boolean;
  highValueNotes?: string;
  temperatureFrom?: number;
  temperatureTo?: number;
  weight: number;
  quantity: number;
  date: number;
  time?: string;
};

export interface ILoadItemsResponseDto {
  shippers: ILoadItem[];
  consignees: ILoadItem[];
};

export interface ILoadItem {
  uuid: string;
  number: number;
  direction: ELoadItemDirection;
  shipperConsigneeId: string;
  description?: string;
  type: ELoadItemType;
  quantity: number;
  weight: number;
  showTime: boolean;
  date: number;
  time?: string;
  highValue: boolean;
  highValueNotes?: string;
  fragility?: EFragilityLevel;
  temperatureFrom?: number;
  temperatureTo?: number;
  notes?: string;
  pallets?: IPalletTypeQuantity[];
};

export interface ILoadItemRequestDto {
  uuid: string;
  number: number;
  direction: ELoadItemDirection;
  shipperConsigneeId: string;
  description?: string;
  type: ELoadItemType;
  quantity: number;
  weight: number;
  showTime: boolean;
  date: number;
  time?: string;
  highValue: boolean;
  highValueNotes?: string;
  fragility?: EFragilityLevel;
  temperatureFrom?: number;
  temperatureTo?: number;
  notes?: string;
  pallets?: IPalletTypeQuantityRequestDto[];
};

export interface ILoadItemsRequestDto {
  shippers: ILoadItemRequestDto[];
  consignees: ILoadItemRequestDto[];
}

export interface ILoadPayRequestDto {
  type: ELoadPayType;
  caption: string;
  amount: number;
  status: ELoadPayStatus;
}

export interface ILoadPayResponseDto {
  uuid: string;
  type: ELoadPayType;
  caption: string;
  amount: number;
  status: ELoadPayStatus;
  createdBy: string;
  createdDate: number;
  updatedBy?: string;
  updatedDate?: number;
}

export interface ILoadUserResponseDto {
  uuid: string;
  user: IUserShortResponseDto;
  main: boolean;
  createdBy: string;
  createdDate: number;
}

export interface ILoadUserRequestDto {
  userId: string;
  main: boolean;
}

export interface ILoadUserPendingResponseDto {
  user: IUserShortResponseDto;
  involvedActiveLoads: number;
}

export interface ILoadTransportResponseDto {
  uuid: string;
  type: EVehicleType;
  transport: ITransportShortResponseDto;
  createdBy: string;
  createdDate: number;
}

export interface ILoadTransportRequestDto {
  transportId: string;
}

export interface ILoadStatusResponseDto {
  uuid: string;
  status: ELoadStatus;
  rateConfirmation: boolean;
  billOfLading: boolean;
  requiresProofs: boolean;
  items: boolean;
  users: boolean;
  transports: boolean;
}

export interface ILoadStatusRequestDto {
  status: ELoadStatus;
}

export interface ILoadDocument {
  documents: IDocument[];
  rateConfirmation: boolean;
  billOfLading: boolean;
}

export interface IFavouritesLoad {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  idno?: string;
  reviewMark: number;
  status: ELoadStatus;
}

export interface ILoadChargeTypeResponseDto {
  uuid: string;
  name: string;
  requiresProof: boolean;
  allowDelete?: boolean;
}

export interface ILoadChargeTypeRequestDto {
  name: string;
  requiresProof: boolean;
}

export interface ILoadChargeResponseDto {
  uuid: string;
  type: ILoadChargeTypeResponseDto;
  amount: number;
  file?: IFileDetailsResponseDto;
  createdBy: string;
  createdDate: number;
}

export interface ILoadChargeRequestDto {
  typeId: string;
  amount: number;
  file?: File;
  removeFile?: boolean;
}

export interface ILoadStatusPreviewResponseDto {
  count: number;
  amount: number;
}

export interface ILoadShipperConsigneeResponseDto {
  uuid: string;
  direction: ELoadItemDirection;
  name: string;
  address: IAddressShortResponseDto;
  contactName?: string;
  contactEmail?: string;
  phone?: string;
  tollFree?: string;
  hours?: string;
  appointments: ELoadAppointmentType;
  majorIntersectionsDirections?: string;
  allowDelete?: boolean;
  status: ELoadShipperConsigneeStatus;
}

export interface ILoadShipperConsigneeRequestDto {
  direction: ELoadItemDirection;
  name: string;
  addressId: string;
  contactName?: string;
  contactEmail?: string;
  phone?: string;
  tollFree?: string;
  hours?: string;
  appointments: ELoadAppointmentType;
  majorIntersectionsDirections?: string;
  status: ELoadShipperConsigneeStatus;
  duplicate: boolean;
}