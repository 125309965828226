import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, styled } from "@mui/material";
import { useCallback, useRef } from "react";
import { isBlank } from "../../../helpers/textHelper";
import BackdropProgress from "../BackdropComponent/BackdropProgress";

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    }
}));
interface IProps {
    loading?: boolean;
    open: boolean;

    title?: string;

    maxWidth?: Breakpoint;
    keepMounted?: boolean;

    contentPadding?: string;
    actionsPadding?: string;

    buildContent: () => JSX.Element;

    buildLeftActions?: () => JSX.Element;
    buildCenterActions?: () => JSX.Element;
    buildRightActions?: () => JSX.Element;

    onCloseBtnClick: () => void;
    closeBtnIconHide?: boolean;
    closeBtnColor?: any;
    closeBtnVariant?: any;
    closeBtnLabel?: string;
}
const BaseDialog = (props: IProps) => {
    const {
        loading, title, maxWidth, keepMounted, onCloseBtnClick, closeBtnIconHide = false,
        closeBtnColor = 'warning', closeBtnVariant = 'contained', closeBtnLabel = 'CLOSE',
        buildContent, buildLeftActions, buildCenterActions, buildRightActions, open,
        contentPadding = '25px', actionsPadding = '15px 25px 20px 25px'
    } = props;

    const isTitle = useRef<boolean>(!isBlank(title));

    const onCloseHandler = useCallback(() => {
        onCloseBtnClick();
    }, [onCloseBtnClick]);

    return (
        <CustomDialog
            fullWidth
            onClose={onCloseHandler}
            aria-labelledby='customized-dialog-title'
            open={open}
            maxWidth={maxWidth}
            keepMounted={keepMounted}
        >
            <BackdropProgress open={loading || false}>
                <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
                    {isTitle && title}
                </DialogTitle>
                <Tooltip title={closeBtnLabel}>
                    <IconButton
                        aria-label='close'
                        onClick={onCloseHandler}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>

                <DialogContent style={{ padding: contentPadding }}>
                    {buildContent()}
                </DialogContent>

                <DialogActions style={{ padding: actionsPadding }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ minWidth: '120px' }}>
                            {buildLeftActions && buildLeftActions()}
                        </div>

                        <div>
                            {buildCenterActions && buildCenterActions()}
                        </div>

                        <div style={{ minWidth: '120px', display: 'flex', justifyContent: 'flex-end', gap: "10px" }}>
                            {buildRightActions && buildRightActions()}
                            <Tooltip title={closeBtnLabel} arrow>
                                <Button
                                    startIcon={closeBtnIconHide ? '' : <CloseIcon />}
                                    color={closeBtnColor}
                                    variant={closeBtnVariant}
                                    size='small'
                                    onClick={onCloseHandler}
                                >
                                    {closeBtnLabel}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </DialogActions>
            </BackdropProgress>
        </CustomDialog>
    );
}
export default BaseDialog;