import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from 'react';
import { EUserEndorsementType } from "../../models/UserModels";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

interface IProps {
    label?: string;
    required?: boolean;
    value?: EUserEndorsementType[];
    onChange?: (EUserEndorsementType: EUserEndorsementType[]) => void;
}
const UserEndorsementSelect = (props: IProps) => {
    const { label = 'Endorsements', required = false, onChange, value } = props;
    const [selectedValues, setSelectedValues] = useState<string[]>(value ? value.map(item => item.toString()) : []);

    const onChangeHandler = useCallback((event: SelectChangeEvent<typeof selectedValues>) => {
        const { target: { value } } = event;
        const newValue: string[] = typeof value === 'string' ? value.split(',') : value;

        setSelectedValues(newValue);

        if (onChange) {
            onChange(newValue ? newValue.map(item => item as EUserEndorsementType) : []);
        }
    }, [onChange]);

    return (
        <FormControl fullWidth required={required}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={selectedValues}
                label={label}
                onChange={onChangeHandler}
                renderValue={(selected) => selected.map(item => item.replaceAll('_', ' ')).join(', ')}
                MenuProps={MenuProps}
            >
                {
                    Object.keys(EUserEndorsementType)
                        .filter(key => isNaN(Number(key)))
                        .filter(key => key !== EUserEndorsementType[EUserEndorsementType.NONE])
                        .map(key =>
                            <MenuItem key={key} value={key}>
                                <Checkbox checked={selectedValues.includes(key)} />
                                <ListItemText primary={key.replaceAll('_', ' ')} />
                            </MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
export default UserEndorsementSelect;