import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Divider, Stack, Tooltip } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from '../../../../helpers/generalHelper';
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from '../../../../hooks/useNotification';
import { ELoadItemDirection, ELoadItemType, ILoadItem, ILoadItemRequestDto, ILoadItemsRequestDto, ILoadItemsResponseDto, IPalletTypeQuantityRequestDto } from "../../../../models/LoadModels";
import LoadService from '../../../../services/LoadService';
import BaseDialog from "../../../Base/BaseDialogComponent/BaseDialog";
import LoadItemsForm from "./LoadItemsForm";
import LoadItemsTabs from "./LoadItemsTabs";

const calculateQuantity = (items: ILoadItem[]): number => {
    return items.filter(item => !isNaN(item.quantity)).reduce((sum, item) => sum + item.quantity, 0);
};

const calculateWeight = (items: ILoadItem[]): number => {
    return items.filter(item => !isNaN(item.weight)).reduce((sum, item) => sum + item.weight, 0);
};

const calculateQuantityAddConsignee = (shippers: ILoadItem[], consignees: ILoadItem[]): number => {
    const quantityShippers: number = calculateQuantity(shippers);
    const quantityConsignees: number = calculateQuantity(consignees);
    const result: number = Math.max(quantityShippers - quantityConsignees, 0);
    return result > quantityShippers ? 0 : result;
};

const calculateWeightAddConsignee = (shippers: ILoadItem[], consignees: ILoadItem[]): number => {
    const weightShippers: number = calculateWeight(shippers);
    const weightConsignees: number = calculateWeight(consignees);
    const result: number = Math.max(weightShippers - weightConsignees, 0);
    return result > weightShippers ? 0 : result;
};

const updateConsigneeOneToMany = (consignee: ILoadItem, shippers: ILoadItem[], fieldName: string): ILoadItem => {
    const newConsignee = { ...consignee };
    let weight: number = 0;
    let quantity: number = 0;

    shippers.forEach((item) => {
        if (!isNaN(item.weight)) {
            weight += item.weight;
        }

        if (!isNaN(item.quantity)) {
            quantity += item.quantity;
        }
    });

    switch (fieldName) {
        case 'weight':
            newConsignee.weight = weight;
            break;
        case 'quantity':
            newConsignee.quantity = quantity;
            break;
    };
    return newConsignee;
};

const updateConsigneeOneToOne = (consignee: ILoadItem, shipper: ILoadItem, fieldName: string): ILoadItem => {
    const newConsignee = { ...consignee };
    switch (fieldName) {
        case 'weight':
            newConsignee.weight = shipper.weight;
            break;
        case 'quantity':
            newConsignee.quantity = shipper.quantity;
            break;
    };
    return newConsignee;
};

const convertToRequest = (items: ILoadItem[]): ILoadItemRequestDto[] => {
    return items.map(item => {
        let pallets: IPalletTypeQuantityRequestDto[] | undefined = undefined;
        if (item.pallets && !isEmpty(item.pallets)) {
            pallets = item.pallets.map(pallet => {
                return {
                    palletTypeId: pallet.pallet.uuid,
                    quantity: pallet.quantity
                }
            });
        }
        return { ...item, pallets: pallets }
    });
};

const validateForm = (item: ILoadItem): boolean => {
    const shipperConsigneeId: boolean = item.shipperConsigneeId !== null && !isBlank(item.shipperConsigneeId);
    const type: boolean = ELoadItemType[item.type] !== undefined && item.type !== ELoadItemType.NONE;
    const weight: boolean = item.weight !== undefined && !isNaN(item.weight);
    const quantity: boolean = item.quantity !== undefined && !isNaN(item.quantity);
    const date: boolean = item.date !== undefined && !isNaN(item.date);
    const time: boolean = item.showTime ? item.time !== undefined && !isBlank(item.time) ? true : false : true;
    return shipperConsigneeId && type && weight && quantity && date && time;
};

const validateWeightAndQuantity = (shippers: ILoadItem[], consignees: ILoadItem[]): boolean => {
    let weightShippers: number = 0;
    let quantityShippers: number = 0;
    let weightConsignees: number = 0;
    let quantityConsignees: number = 0;
    let palletsShippers: number = 0;
    let palletsConsignees: number = 0;

    shippers.forEach(shipper => {
        if (!isNaN(shipper.weight)) {
            weightShippers += shipper.weight;
        }

        if (!isNaN(shipper.quantity)) {
            quantityShippers += shipper.quantity;
        }

        if (shipper.pallets) {
            palletsShippers = shipper.pallets.reduce((sum, item) => sum + item.quantity, 0);
        }
    });

    consignees.forEach(consignee => {
        if (!isNaN(consignee.weight)) {
            weightConsignees += consignee.weight;
        }

        if (!isNaN(consignee.quantity)) {
            quantityConsignees += consignee.quantity;
        }

        if (consignee.pallets) {
            palletsConsignees = consignee.pallets.reduce((sum, item) => sum + item.quantity, 0);
        }
    });

    return weightShippers === weightConsignees
        && quantityShippers === quantityConsignees
        && quantityShippers >= palletsShippers
        && quantityConsignees >= palletsConsignees
};

const calculateNextTabNumber = (data: ILoadItem[]): number =>
    data.reduce((max, item) => Math.max(max, item.number), 0) + 1;

const defaultShipper: ILoadItem = {
    uuid: '',
    number: 1,
    direction: ELoadItemDirection.SHIPPER,
    shipperConsigneeId: '',
    description: '',
    type: ELoadItemType.NONE,
    quantity: NaN,
    weight: NaN,
    showTime: false,
    date: NaN,
    time: '',
    highValue: false,
    highValueNotes: '',
    fragility: undefined,
    temperatureFrom: NaN,
    temperatureTo: NaN,
    notes: ''
};

const defaultConsignee: ILoadItem = {
    uuid: '',
    number: 1,
    direction: ELoadItemDirection.CONSIGNEE,
    shipperConsigneeId: '',
    description: '',
    type: ELoadItemType.NONE,
    quantity: NaN,
    weight: NaN,
    showTime: false,
    date: NaN,
    time: '',
    highValue: false,
    highValueNotes: '',
    fragility: undefined,
    temperatureFrom: NaN,
    temperatureTo: NaN,
    notes: ''
};

interface IProps {
    open: boolean;
    loadId: string;
    onCloseBtnClick: () => void;
    onSubmitBtnClick: () => void;
}
const LoadItemsDialog = (props: IProps) => {
    const { open, loadId, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();

    const [loading, setLoading] = useState(false);
    const [shippers, setShippers] = useState<ILoadItem[]>([]);
    const [consignees, setConsignees] = useState<ILoadItem[]>([]);
    const [shipperTab, setShipperTab] = useState<number>(0);
    const [consigneeTab, setConsigneeTab] = useState<number>(0);
    const [valid, setValid] = useState<boolean>(false);
    const initialShippers = useRef(shippers);
    const initialConsignee = useRef(consignees);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const [error, response] = await LoadService.fetchItemsDialog(loadId);
            if (response) {
                const responseData: ILoadItemsResponseDto = response.data.body;
                const shippers: ILoadItem[] = isEmpty(responseData.shippers) ? [{ ...defaultShipper }] : responseData.shippers;
                const consignees: ILoadItem[] = isEmpty(responseData.consignees) ? [{ ...defaultConsignee }] : responseData.consignees;

                setShippers(shippers);
                setConsignees(consignees);

                initialShippers.current = shippers;
                initialConsignee.current = consignees;

                setValid(true);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId]);

    const isDirty: boolean = (JSON.stringify(shippers) !== JSON.stringify(initialShippers.current))
        || (JSON.stringify(consignees) !== JSON.stringify(initialConsignee.current));

    const validate = useCallback((shippers: ILoadItem[], consignees: ILoadItem[]) => {
        const invalidShippers: boolean = shippers.some(item => !validateForm(item));
        const invalidConsignees: boolean = consignees.some(item => !validateForm(item));
        const validWeightAndQuantity: boolean = validateWeightAndQuantity(shippers, consignees);
        setValid(!invalidShippers && !invalidConsignees && validWeightAndQuantity);
    }, []);

    const onAddShipperHandler = useCallback(() => {
        const nr: number = calculateNextTabNumber(shippers);
        const newItem: ILoadItem = { ...defaultShipper, number: nr };

        const newShippers: ILoadItem[] = [...shippers, newItem];
        setShippers(newShippers);
        setShipperTab(shipperTab => shipperTab + 1);

        validate(newShippers, consignees);
    }, [consignees, shippers, validate]);

    const onAddConsigneeHandler = useCallback(() => {
        const nr: number = calculateNextTabNumber(consignees);
        const quantity: number = calculateQuantityAddConsignee(shippers, consignees);
        const weight: number = calculateWeightAddConsignee(shippers, consignees);
        const newItem: ILoadItem = { ...defaultConsignee, number: nr, quantity, weight };

        const newConsignees: ILoadItem[] = [...consignees, newItem];
        setConsignees(newConsignees);
        setConsigneeTab(consigneeTab => consigneeTab + 1);

        validate(shippers, newConsignees);
    }, [consignees, shippers, validate]);

    const onRemoveShipperHandler = useCallback((index: number) => {
        const newShippers: ILoadItem[] = [...shippers];
        newShippers.splice(index, 1);

        setShippers(newShippers);
        setShipperTab(shipperTab => shipperTab > 0 ? shipperTab - 1 : 0);

        validate(newShippers, consignees);
    }, [consignees, shippers, validate]);

    const onRemoveConsigneeHandler = useCallback((index: number) => {
        const newConsignees: ILoadItem[] = [...consignees];
        newConsignees.splice(index, 1);

        setConsignees(newConsignees);
        setConsigneeTab(consigneeTab => consigneeTab > 0 ? consigneeTab - 1 : 0);

        validate(shippers, newConsignees);
    }, [consignees, shippers, validate]);

    const onUpdateShipperHandler = useCallback((newItem: ILoadItem, index: number, fieldName: string) => {
        const updatedShippers: ILoadItem[] = [...shippers];
        updatedShippers[index] = { ...newItem };

        const updatedConsignees: ILoadItem[] = [...consignees];
        if (updatedShippers.length === 1 && updatedConsignees.length === 1) {
            const originalConsignee: ILoadItem = updatedConsignees[consigneeTab];
            const updatedConsignee: ILoadItem = updateConsigneeOneToOne(originalConsignee, newItem, fieldName);
            updatedConsignees[consigneeTab] = updatedConsignee;
        } else if (updatedShippers.length > 1 && updatedConsignees.length === 1) {
            const originalConsignee: ILoadItem = updatedConsignees[consigneeTab];
            const updatedConsignee: ILoadItem = updateConsigneeOneToMany(originalConsignee, updatedShippers, fieldName);
            updatedConsignees[consigneeTab] = updatedConsignee;
        }

        setShippers(updatedShippers);
        setConsignees(updatedConsignees);
        validate(updatedShippers, updatedConsignees);
    }, [consigneeTab, consignees, shippers, validate]);

    const onUpdateConsigneeHandler = useCallback((newItem: ILoadItem, index: number) => {
        const updatedConsignees: ILoadItem[] = [...consignees];
        updatedConsignees[index] = newItem;

        setConsignees(updatedConsignees);
        validate(shippers, updatedConsignees);
    }, [consignees, shippers, validate]);

    const onShipperTabChangeHandler = useCallback((newValue: number) => {
        setShipperTab(newValue);
    }, []);

    const onConsigneeTabChangeHandler = useCallback((newValue: number) => {
        setConsigneeTab(newValue);
    }, []);

    const onSubmitChangeHandler = useCallback(() => {
        const updateData = async () => {
            setLoading(true);

            const request: ILoadItemsRequestDto = {
                shippers: convertToRequest(shippers),
                consignees: convertToRequest(consignees)
            };

            const [error, response] = await LoadService.crudItems(loadId, request);
            if (response) {
                displayNotification({ message: t('Shipper(s) and Consignee(s) were successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        };

        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consignees, loadId, onCloseBtnClick, onSubmitBtnClick, shippers, t]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('SAVE')} arrow>
                <span>
                    <Button
                        variant='contained'
                        disabled={!isDirty || !valid}
                        size='small'
                        startIcon={<SaveAsIcon />}
                        onClick={onSubmitChangeHandler}
                    >
                        {t('SAVE')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [isDirty, onSubmitChangeHandler, t, valid]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={3} minHeight={520}>
                <Box>
                    <LoadItemsTabs
                        tab={shipperTab}
                        items={shippers}
                        onAdd={onAddShipperHandler}
                        onRemove={onRemoveShipperHandler}
                        onChange={onShipperTabChangeHandler}
                    />

                    {shippers[shipperTab] && (
                        <Box marginTop={2}>
                            <LoadItemsForm
                                index={shipperTab}
                                item={shippers[shipperTab]}
                                onChange={onUpdateShipperHandler}
                            />
                        </Box>
                    )}
                </Box>

                <Divider />

                <Box>
                    <LoadItemsTabs
                        tab={consigneeTab}
                        items={consignees}
                        onAdd={onAddConsigneeHandler}
                        onRemove={onRemoveConsigneeHandler}
                        onChange={onConsigneeTabChangeHandler}
                    />

                    {consignees[consigneeTab] && (
                        <Box marginTop={2}>
                            <LoadItemsForm
                                index={consigneeTab}
                                item={consignees[consigneeTab]}
                                onChange={onUpdateConsigneeHandler}
                            />
                        </Box>
                    )}
                </Box>
            </Stack>
        );
    }, [
        consigneeTab, consignees, onAddConsigneeHandler, onAddShipperHandler, onConsigneeTabChangeHandler,
        onRemoveConsigneeHandler, onRemoveShipperHandler, onShipperTabChangeHandler, onUpdateConsigneeHandler,
        onUpdateShipperHandler, shipperTab, shippers
    ]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={'SHIPPER(S) & CONSIGNEE(S) MANAGEMENT'}
            maxWidth={'xl'}
            contentPadding='0px 25px 25px 25px'
            actionsPadding='2px 25px 20px 25px'
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadItemsDialog;