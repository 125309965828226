import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useInvoice } from "../../../hooks/useInvoice";
import { ECriteriaExpression, ICriteria } from "../../../models/CommonModels";
import { ICustomerAutocompleteResponseDto } from "../../../models/CustomerModels";
import { EInvoiceStatus } from "../../../models/InvoiceModels";
import { IUserAutocompleteResponseDto } from "../../../models/UserModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import DateRangeField from "../../Base/DateComponent/DateRangeField";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";
import CustomerAutocomplete from "../../CustomerModule/CustomerAutocomplete";
import UserAutocomplete from "../../UserModule/UserAutocomplete";

const statusData: string[] = Object.keys(EInvoiceStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== EInvoiceStatus[EInvoiceStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    onClose: () => void;
}
const InvoiceFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useInvoice();
    const { criterias } = useSelector((state: RootState) => state.invoiceSlice.grid);

    const [idno, setIdno] = useState<string>('');
    const [customerId, setCustomerId] = useState<string>('');
    const [dateFrom, setDateFrom] = useState<number | undefined>(undefined);
    const [dateTo, setDateTo] = useState<number | undefined>(undefined);
    const [status, setStatus] = useState<EInvoiceStatus | undefined>(undefined);
    const [userId, setUserId] = useState<string>('');

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        setIdno(getValueFromCriterias('idno', criterias) || '');
        setCustomerId(getValueFromCriterias('customer.uuid', criterias) || '');

        const dateRangeStr: string = getValueFromCriterias('auditEntity.createdDate', criterias) || '';
        if (isBlank(dateRangeStr)) {
            setDateFrom(undefined);
            setDateTo(undefined);
        } else {
            const dateRange: string[] = dateRangeStr.split(',');
            setDateFrom(Number(dateRange[0]));
            setDateTo(Number(dateRange[1]));
        }

        const statusStr: string | undefined = getValueFromCriterias('load.status', criterias);
        setStatus(statusStr as EInvoiceStatus || EInvoiceStatus.NONE);

        setUserId(getValueFromCriterias('load.users.user.uuid', criterias) || '');
    }, [criterias, getValueFromCriterias]);

    const onChangeIdnoHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIdno(event.target.value);
    }, []);

    const onChangeCustomerHandler = useCallback((customer: ICustomerAutocompleteResponseDto | null) => {
        setCustomerId(customer?.uuid || '');
    }, []);

    const onChangeDateRangeHandler = useCallback((fromDate?: number, toDate?: number) => {
        setDateFrom(fromDate);
        setDateTo(toDate);
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as EInvoiceStatus);
    }, []);

    const onChangeUserHandler = useCallback((user: IUserAutocompleteResponseDto | null) => {
        setUserId(user?.uuid || '');
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (idno && !isBlank(idno)) {
            newCriterias.push({
                property: 'idno',
                value: idno,
                expression: ECriteriaExpression.EQUALS_IGNORE_CASE
            });
        }

        if (customerId && !isBlank(customerId)) {
            newCriterias.push({
                property: 'customer.uuid',
                value: customerId,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (dateFrom && dateTo) {
            newCriterias.push({
                property: 'auditEntity.createdDate',
                value: `${dateFrom},${dateTo}`,
                expression: ECriteriaExpression.BETWEEN_DATE
            });
        }

        if (status) {
            newCriterias.push({
                property: 'load.status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (userId && !isBlank(userId)) {
            newCriterias.push({
                property: 'load.users.user.uuid',
                value: userId,
                expression: ECriteriaExpression.EQUALS
            });
        }

        return newCriterias;
    }, [customerId, dateFrom, dateTo, idno, status, userId]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('ID#')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        value={idno}
                        onChange={onChangeIdnoHandler}
                    />

                    <UserAutocomplete
                        label={t('USER')}
                        size='small'
                        value={userId}
                        onChange={onChangeUserHandler}
                        showStatus
                        disableInactiveItems
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <CustomerAutocomplete
                        label={t('CUSTOMER')}
                        value={customerId}
                        size='small'
                        onChange={onChangeCustomerHandler}
                    />

                    <DateRangeField
                        alignDirection='row'
                        label={t('DATE')}
                        requiredToFrom
                        fromDateLabel={t('FROM')}
                        toDateLabel={t('TO')}
                        toDateValue={dateTo}
                        fromDateValue={dateFrom}
                        onChange={onChangeDateRangeHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData}
                        value={status}
                        onChange={onChangeStatusHandler}
                    />
                </Stack>
            </Stack>
        );
    }, [
        customerId, dateFrom, dateTo, idno, onChangeCustomerHandler, onChangeDateRangeHandler,
        onChangeIdnoHandler, onChangeStatusHandler, onChangeUserHandler, status, t, userId
    ]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default InvoiceFilter;