import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isBlank } from '../../../helpers/textHelper';
import { IAddressShortResponseDto } from '../../../models/AddressModels';
import AddressDialog from './AddressDialog';

const defaultAddress: IAddressShortResponseDto = {
    uuid: '',
    value: ''
};

interface IProps {
    required?: boolean;
    label?: string;
    size?: 'small' | 'medium';
    value?: IAddressShortResponseDto | undefined;
    disabled?: boolean;
    onChange: (address: IAddressShortResponseDto) => void;
}
const Address = (props: IProps) => {
    const { required = false, label, disabled = false, size = 'medium', onChange, value } = props;

    const [addressDialogToggle, setAddressDialogToggle] = useState(false);
    const [address, setAddress] = useState<IAddressShortResponseDto>(value || defaultAddress);

    const { t } = useTranslation();

    useEffect(() => {
        if (value) {
            setAddress(value);
        }
    }, [value]);

    const onAddressDialogToggleHandler = useCallback(() => {
        setAddressDialogToggle(!addressDialogToggle);
    }, [addressDialogToggle]);

    const onApplyBtnClickHandler = useCallback((address: IAddressShortResponseDto) => {
        onChange(address);
        setAddress(address);
    }, [onChange]);

    const onClearBtnClickHandler = useCallback(() => {
        onChange(defaultAddress);
        setAddress(defaultAddress);
    }, [onChange]);

    const onAddressTextFieldAdornmentBuild = useCallback(() => {
        return ({
            endAdornment: <InputAdornment position='start'>
                <IconButton disabled={disabled} edge='end' onClick={onClearBtnClickHandler}>
                    <CloseIcon fontSize='small' />
                </IconButton>
            </InputAdornment>
        });
    }, [disabled, onClearBtnClickHandler]);

    return (
        <Box width='100%'>
            <Stack direction='row' spacing={1}>
                <Tooltip title={t('Select an address')}>
                    <span>
                        <Button
                            variant='contained'
                            onClick={onAddressDialogToggleHandler}
                            color='info'
                            disabled={disabled}
                            size={size}
                            sx={{ height: '100%' }}
                        >
                            <AddLocationAltIcon />
                        </Button>
                    </span>
                </Tooltip>

                <Tooltip title={address?.value}>
                    <TextField
                        required={required}
                        label={label || t('ADDRESS')}
                        slotProps={{
                            htmlInput: { readOnly: true },
                            input: isBlank(address.uuid) ? {} : onAddressTextFieldAdornmentBuild()
                        }}
                        fullWidth
                        size={size}
                        value={address?.value}
                        disabled={disabled}
                    />
                </Tooltip>
            </Stack>

            {addressDialogToggle &&
                <AddressDialog
                    open={addressDialogToggle}
                    onApplyBtnClick={onApplyBtnClickHandler}
                    onCloseBtnClick={onAddressDialogToggleHandler}
                />
            }
        </Box >
    );
}
export default Address;