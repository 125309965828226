import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateFormat, getTimeFormat } from "../../../../helpers/dateHelper";
import { useLoad } from '../../../../hooks/useLoad';
import { IBaseGridActionBtn, IFilter } from "../../../../models/CommonModels";
import { ILoadItemResponseDto, ILoadShipperConsigneeIdNameAddressResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import LoadItemsDialog from "./LoadItemsDialog";

interface IProps {
    loadId: string;
}
const LoadItemsGrid = (props: IProps) => {
    const { loadId } = props;

    const { t } = useTranslation();
    const { mass, temperature } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.itemsGrid);
    const { gridRefresh, stepRefresh, itemsGridRefresh } = useLoad();

    const [dialogToggle, setDialogToggle] = useState(false);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchItems(loadId, filter);
    }, [loadId]);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onShipperConsigneeColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ILoadShipperConsigneeIdNameAddressResponseDto = params.value;
        return (
            <Tooltip title={value.address.value}>
                <span>{value.name}</span>
            </Tooltip>
        );
    }, []);

    const onTemperatureColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadItemResponseDto = params.row;

        let value: string = '';
        if (row.temperatureFrom && row.temperatureTo) {
            value += `${row.temperatureFrom} - ${row.temperatureTo} ${temperature}`;
        } else if (row.temperatureFrom && !row.temperatureTo) {
            value = `${row.temperatureFrom} ${temperature}`;
        } else if (!row.temperatureFrom && row.temperatureTo) {
            value = `${row.temperatureTo} ${temperature}`;
        }

        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [temperature]);

    const onMassColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = `${params.value} ${mass}`;
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [mass]);

    const onDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = getDateFormat(params.value, dateFormat);
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [dateFormat]);

    const onTimeColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value ? getTimeFormat(params.value, timeFormat) : '';
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [timeFormat]);

    const onHighValueColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadItemResponseDto = params.row;
        const priority: string = row.highValue ? t('YES') : t('NO');
        return (
            <Tooltip title={row.highValueNotes}>
                <span>{priority}</span>
            </Tooltip>
        );
    }, [t]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'direction',
            headerName: t('DIRECTION'),
            width: 100,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            hideable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'shipperConsignee',
            headerName: t('SHP / CNE'),
            minWidth: 110,
            flex: 1,
            headerAlign: 'center',
            hideable: false,
            renderCell: onShipperConsigneeColumnRender
        }, {
            field: 'type',
            headerName: t('TYPE'),
            width: 55,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'notes',
            headerName: t('NOTES'),
            width: 150,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'highValue',
            headerName: t('H.V.'),
            width: 60,
            headerAlign: 'center',
            renderCell: onHighValueColumnRender
        }, {
            field: 'temperature',
            headerName: t('TMP'),
            width: 100,
            headerAlign: 'center',
            renderCell: onTemperatureColumnRender
        }, {
            field: 'weight',
            headerName: t('WEIGHT'),
            width: 100,
            headerAlign: 'center',
            renderCell: onMassColumnRender
        }, {
            field: 'quantity',
            headerName: t('QTY'),
            width: 80,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'date',
            headerName: t('DATE'),
            width: 105,
            headerAlign: 'center',
            renderCell: onDateColumnRender
        }, {
            field: 'time',
            headerName: t('TIME'),
            width: 100,
            headerAlign: 'center',
            renderCell: onTimeColumnRender
        }];
    }, [
        t, onTooltipColumnRender, onShipperConsigneeColumnRender, onHighValueColumnRender,
        onTemperatureColumnRender, onMassColumnRender, onDateColumnRender, onTimeColumnRender
    ]);

    const dialogToggleHandler = useCallback(() => {
        setDialogToggle(dialogToggle => !dialogToggle);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        itemsGridRefresh();
        stepRefresh();
        gridRefresh();
    }, [gridRefresh, itemsGridRefresh, stepRefresh]);

    const actionBtns = useMemo((): IBaseGridActionBtn[] => {
        return [{
            tooltip: t('Shipper(s) & consignee(s) management'),
            onClick: dialogToggleHandler,
            icon: <EditIcon fontSize='small' />,
            label: t('MANAGE'),
            disabled: false,
            key: 'load-items-manage-action-btn'
        }];
    }, [dialogToggleHandler, t]);

    return (
        <>
            <BaseCrudGrid
                gridId='load-items'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={false}
                retrieveDataApi={onRetrieveDataApi}
                actionBtns={actionBtns}
                addActionBtnHide
                editActionBtnHide
                deleteActionBtnHide
                refreshActionBtnTooltip={t('Refresh data')}
            />

            {dialogToggle &&
                <LoadItemsDialog
                    open={dialogToggle}
                    loadId={loadId}
                    onCloseBtnClick={dialogToggleHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default LoadItemsGrid;