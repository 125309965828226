import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import SpeedIcon from '@mui/icons-material/Speed';
import { Box, Step, StepButton, StepContent, StepIconProps, Stepper, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { IStepItem } from "../../../models/CommonModels";
import BaseDialog from '../../Base/BaseDialogComponent/BaseDialog';
import GlobalPreferenceCompany from "./Step/GlobalPreferenceCompany";
import GlobalPreferenceDateTime from "./Step/GlobalPreferenceDateTime";
import GlobalPreferenceInvoice from "./Step/GlobalPreferenceInvoice";
import GlobalPreferenceMeasure from "./Step/GlobalPreferenceMeasure";

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
}
const GlobalPreferenceDialog = (props: IProps) => {
    const { open, onCloseBtnClick } = props;
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState<number>(- 1);

    const onChangeStepHandler = useCallback((step: number) => {
        if (step === activeStep) {
            setActiveStep(-1);
        } else {
            setActiveStep(step);
        }
    }, [activeStep]);

    const items = useMemo((): IStepItem[] => {
        return [{
            title: t('COMPANY'),
            content: <GlobalPreferenceCompany />,
            icon: <BusinessIcon />
        }, {
            title: t('MEASUREMENT UNITS'),
            content: <GlobalPreferenceMeasure />,
            icon: <SpeedIcon />
        }, {
            title: t('DATE AND TIME FORMAT'),
            content: <GlobalPreferenceDateTime />,
            icon: <CalendarMonthIcon />
        }, {
            title: t('INVOICE'),
            content: <GlobalPreferenceInvoice />,
            icon: <RequestPageIcon />
        }];
    }, [t]);

    const onBuildContent = useCallback(() => {
        const StepIcon: React.FC<StepIconProps & { icon: React.ReactElement, step: number }> = ({ icon, step }) => {
            const active: boolean = step === activeStep;
            return React.cloneElement(icon, {
                style: { color: active ? 'black' : 'rgba(0, 0, 0, 0.38)' }
            });
        };

        return (
            <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
                {
                    items.map((item, index) =>
                        <Step
                            key={item.title}
                            completed={false}
                        >
                            <StepButton
                                sx={{ '&:hover': { backgroundColor: 'rgb(242 242 242 / 87%)' } }}
                                color='inherit'
                                icon={item.icon ? <StepIcon {...props} icon={item.icon} step={index} /> : undefined}
                                onClick={() => onChangeStepHandler(index)}
                            >
                                <Box display='flex' alignItems='center' justifyContent='flex-start'>
                                    <Typography variant="body2">{item.title}</Typography>
                                </Box>
                            </StepButton>
                            <StepContent>
                                {item.content}
                            </StepContent>
                        </Step>
                    )
                }
            </Stepper>
        );
    }, [activeStep, items, onChangeStepHandler, props]);

    return (
        <BaseDialog
            open={open}
            title={t('GLOBAL PREFERENCES')}
            maxWidth={'md'}
            buildContent={onBuildContent}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnColor={'primary'}
            closeBtnVariant={'text'}
            closeBtnIconHide={true}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default GlobalPreferenceDialog;