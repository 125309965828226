import { IGlobalPreferenceCompanyRequestDto, IGlobalPreferenceInvoiceRequestDto, IGlobalPreferenceMeasureRequestDto, IPreferenceDateTimeRequestDto } from "../models/PreferenceModels";
import { API } from "./APIService";

class GlobalPreferenceService {
    public static fetch = async (): Promise<any> => {
        const [error, response] = await API('GET', "globalPreference");
        return [error, response];
    };

    public static updateCompany = async (data: IGlobalPreferenceCompanyRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', 'globalPreference/company', JSON.stringify(data));
        return [error, response];
    };

    public static updateCompanyLogo = async (data: FormData): Promise<any> => {
        const [error, response] = await API('PUT', 'globalPreference/company/logo', data, undefined, 'multipart/form-data');
        return [error, response];
    };

    public static updateMeasure = async (data: IGlobalPreferenceMeasureRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', 'globalPreference/measure', JSON.stringify(data));
        return [error, response];
    };

    public static updateDateTime = async (data: IPreferenceDateTimeRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', 'globalPreference/dateTime', JSON.stringify(data));
        return [error, response];
    };

    public static updateInvoice = async (data: IGlobalPreferenceInvoiceRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', 'globalPreference/invoice', JSON.stringify(data));
        return [error, response];
    };
}
export default GlobalPreferenceService;