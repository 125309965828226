import { Box, Tab, Tabs } from "@mui/material";
import { useCallback } from "react";
import { ILoadItem } from "../../../../models/LoadModels";
import BaseTabAddBtn from "../../../Base/BaseTabComponent/BaseTabAddBtn";
import BaseTabCloseBtn from "../../../Base/BaseTabComponent/BaseTabCloseBtn";

interface IProps {
    tab: number;
    items: ILoadItem[];

    onAdd: () => void;
    onRemove: (index: number) => void;
    onChange: (newValue: number) => void;
}
const LoadItemsTabs = (props: IProps) => {
    const { tab, items, onAdd, onRemove, onChange } = props;

    const onChangeHandler = useCallback((event: React.SyntheticEvent, newValue: number) => {
        onChange(newValue);
    }, [onChange]);

    return (
        <Box display='flex' alignItems='center' gap={1}>
            <Tabs
                value={tab}
                onChange={onChangeHandler}
                variant='scrollable'
                sx={{
                    minHeight: 0,
                    '& .MuiTabs-indicator': { bottom: 0, height: 2 }
                }}
            >
                {items.map((item, idx) =>
                    <Tab
                        key={`tab-item-button-${item.number}`}
                        value={idx}
                        label={
                            <BaseTabCloseBtn
                                id={item.number.toString()}
                                label={`${item.direction} ${item.number}`}
                                closeable={idx !== 0}
                                onClick={() => onRemove(idx)}
                            />
                        }
                        sx={{ minHeight: 30, height: 30, padding: '2px 5px 2px 5px', margin: '0px 5px 0px 5px' }}
                    />
                )}
            </Tabs >
            <BaseTabAddBtn onClick={onAdd} />
        </Box >
    );
}
export default LoadItemsTabs;