import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ELoadItemType } from "../../../models/LoadModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: ELoadItemType;
    size?: 'medium' | 'small';
    onChange?: (event: SelectChangeEvent) => void;
}
const LoadItemTypeSelect = (props: IProps) => {
    const { label = 'Type', required = false, size = 'medium', onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>('');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
    }, [onChange]);

    useEffect(() => {
        setSelectedValue(props.value ? props.value.toString() : '');
    }, [props.value]);

    return (
        <FormControl fullWidth required={required} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                {
                    Object.keys(ELoadItemType)
                        .filter(key => isNaN(Number(key)))
                        .filter(key => key !== ELoadItemType[ELoadItemType.NONE])
                        .map(key => <MenuItem key={key} value={key}>{key.toString()}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
export default LoadItemTypeSelect;