import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertTimestampToDateStrWithFormatOrEmpty } from '../../../../helpers/dateHelper';
import { IItemDetails } from '../../../../models/CommonModels';
import { IDocument } from "../../../../models/DocumentModels";
import { EDateFormat } from '../../../../models/PreferenceModels';
import { EUserDocumentType } from '../../../../models/UserModels';
import { RootState } from '../../../../store/store';
import BaseDocumentItem from "../../../Base/BaseDocumentComponent/BaseDocumentItem";

const countryFiled: string[] = [
    EUserDocumentType.PASSPORT.toString(),
    EUserDocumentType.DRIVER_LICENSE.toString(),
    EUserDocumentType.DOCUMENT.toString()
];
const issueDateField: string[] = [
    EUserDocumentType.PASSPORT.toString(),
    EUserDocumentType.DOCUMENT.toString()
];

const convertDateToString = (value?: number, dateFormat?: EDateFormat) => {
    return value ? convertTimestampToDateStrWithFormatOrEmpty(value, dateFormat) : '';
};

interface IProps {
    document: IDocument;
    expanded: boolean;
    maxHeight: string | number;

    onChangeAcordeon: (value: string | false) => void;
    onEditBtnClick: (document: IDocument) => void;
    onDeleteBtnClick: (uuid: string) => void;
    onSubmitAttachment: (documentId: string, file: File) => void;
    onDeleteAttachment: (documentId: string, attachmentId: string) => void;
}
const UserDocumentItem = (props: IProps) => {
    const {
        document, expanded, maxHeight, onEditBtnClick, onDeleteBtnClick,
        onChangeAcordeon, onSubmitAttachment, onDeleteAttachment
    } = props;

    const { t } = useTranslation();
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const [items, setItems] = useState<IItemDetails[]>([]);

    useEffect(() => {
        const itemsDetails: IItemDetails[] = [{
            id: document.uuid,
            primary: t('TYPE'),
            secondary: document.type.replaceAll('_', ' ')
        }, {
            id: document.uuid,
            primary: t('NUMBER'),
            secondary: document.number
        }, {
            id: document.uuid,
            primary: t('NOTES'),
            secondary: document.caption
        }];

        if (countryFiled.includes(document.type)) {
            itemsDetails.push({
                id: document.uuid,
                primary: t('COUNTRY'),
                secondary: document.issueCountry?.country
            });

            itemsDetails.push({
                id: document.uuid,
                primary: t('STATE'),
                secondary: document.issueState?.state
            });
        }

        if (issueDateField.includes(document.type)) {
            itemsDetails.push({
                id: document.uuid,
                primary: t('DATE OF ISSUE'),
                secondary: convertDateToString(document.dateOfIssue, dateFormat)
            });
        }

        itemsDetails.push({
            id: document.uuid,
            primary: t('EXPIRATION DATE'),
            secondary: convertDateToString(document.expirationDate, dateFormat)
        });

        itemsDetails.push({
            id: document.uuid,
            primary: t('CREATED DATE'),
            secondary: convertDateToString(document.createdDate, dateFormat)
        });

        setItems(itemsDetails);
    }, [
        dateFormat, document.caption, document.createdDate, document.dateOfIssue, 
        document.expirationDate, document.issueCountry?.country, document.issueState?.state, 
        document.number, document.type, document.uuid, t
    ]);

    return (
        <BaseDocumentItem
            document={document}
            expanded={expanded}
            maxHeight={maxHeight}
            onChangeAcordeon={onChangeAcordeon}
            summary={document.type.replaceAll('_', ' ')}
            itemsDetails={items}
            editBtnLabel={t('EDIT')}
            editBtnTooltip={t('Edit document')}
            onEditBtnClick={onEditBtnClick}
            deleteBtnLabel={t('DELETE')}
            deleteBtnTooltip={t('Delete document')}
            onDeleteBtnClick={onDeleteBtnClick}
            addAttachmentBtnLabel={t('ATTACHMENT')}
            addAttachmentBtnTooltip={t('Add attachment')}
            onSubmitAttachment={onSubmitAttachment}
            onDeleteAttachment={onDeleteAttachment}
        />
    );
}
export default UserDocumentItem;