export enum EAuthority {
    NONE,
    OWNER = 'OWNER',
    ADMINISTRATOR = 'ADMINISTRATOR',
    ACCOUNTANT = 'ACCOUNTANT',
    DISPATCHER = 'DISPATCHER',
    DISPATCHER_ADVANCED = 'DISPATCHER_ADVANCED',
    DRIVER = 'DRIVER'
}

export interface IRoleIdNameResponseDto {
    uuid: string;
    name: EAuthority;
};