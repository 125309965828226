import ContactMailIcon from '@mui/icons-material/ContactMail';
import DescriptionIcon from '@mui/icons-material/Description';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FolderIcon from '@mui/icons-material/Folder';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationHomeUrl } from "../../../helpers/generalHelper";
import { useCustomer } from "../../../hooks/useCustomer";
import { useNotification } from "../../../hooks/useNotification";
import { IStepItem } from "../../../models/CommonModels";
import { ICustomerCoverFaceResponseDto } from "../../../models/CustomerModels";
import CustomerService from "../../../services/CustomerService";
import { RootState } from "../../../store/store";
import BaseStep from "../../Base/BaseStepComponent/BaseStep";
import CustomerContactsStep from "./Contacts/CustomerContactsStep";
import CustomerStepCoverFace from "./CustomerStepCoverFace";
import CustomerDescriptionStep from "./Description/CustomerDescriptionStep";
import CustomerDocumentsStep from "./Documents/CustomerDocumentsStep";
import CustomerOverviewStep from "./Overview/CustomerOverviewStep";
import CustomerReviewsStep from "./Reviews/CustomerReviewsStep";
import CustomerStatusAndReputationStep from "./StatusAndReputation/CustomerStatusAndReputationStep";

const CustomerStep = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const navigate = useNavigate();
    const { refresh } = useSelector((state: RootState) => state.customerSlice.step);
    const { gridRefresh } = useCustomer();

    const onRetrieveDataApiHandler = useCallback((id: string) => {
        return CustomerService.fetchCoverFaceData(id);
    }, []);

    const onBuildCoverFaceHandler = useCallback((data: ICustomerCoverFaceResponseDto): React.ReactNode => {
        return <CustomerStepCoverFace data={data} />;
    }, []);

    const onBuildItemsHandler = useCallback((data: ICustomerCoverFaceResponseDto): IStepItem[] => {
        const id: string = data.uuid;
        const readonly: boolean = data.readonly;
        return [{
            title: t('OVERVIEW'),
            content: <CustomerOverviewStep customerId={id} readonly={readonly} />,
            icon: <FileOpenIcon />
        }, {
            title: t('CONTACTS'),
            content: <CustomerContactsStep customerId={id} readonly={readonly} />,
            icon: <ContactMailIcon />
        }, {
            title: t('DESCRIPTION'),
            content: <CustomerDescriptionStep customerId={id} />,
            icon: <DescriptionIcon />
        }, {
            title: t('DOCUMENTS'),
            content: <CustomerDocumentsStep customerId={id} />,
            icon: <FolderIcon />
        }, {
            title: t('REVIEW'),
            content: <CustomerReviewsStep customerId={id} />,
            icon: <ReviewsIcon />
        }, {
            title: t('STATUS & REPUTATION'),
            content: <CustomerStatusAndReputationStep customerId={id} readonly={readonly} />,
            icon: <SwapHorizontalCircleIcon />
        }];
    }, [t]);

    const onDeleteBtnClickHandler = useCallback((uuid: string) => {
        (async () => {
            const [error] = await CustomerService.remove(uuid);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
            } else {
                const newUrl: string = normalizeNavigationHomeUrl(true);
                navigate(`/${newUrl}`);
                displayNotification({ message: t('Customer was successfully deleted.') });
                gridRefresh();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <BaseStep
            retrieveDataApi={onRetrieveDataApiHandler}
            refresh={refresh}
            buildCoverFace={onBuildCoverFaceHandler}
            buildItems={onBuildItemsHandler}
            onDeleteBtnClick={onDeleteBtnClickHandler}
            deleteBtnTooltip={t('Delete item')}
            closeBtnTooltip={t('Close and return to the main list')}
        />
    );
}
export default CustomerStep;