import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Checkbox, FormControlLabel, IconButton, SelectChangeEvent, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { EFragilityLevel } from '../../../../models/CommonModels';
import { ELoadAppointmentType, ELoadItemType, ILoadItem, ILoadShipperConsigneeResponseDto, IPalletTypeQuantity } from "../../../../models/LoadModels";
import { RootState } from '../../../../store/store';
import DateField from '../../../Base/DateComponent/DateField';
import TimeField from '../../../Base/DateComponent/TimeField';
import NumberField from '../../../Base/NumberFieldComponent/NumberField';
import FragilityLevelSelect from '../../Fragility/FragilityLevelSelect';
import PalletTypeQuantity from '../../PalletTypeQuantity/PalletTypeQuantity';
import ShipperConsigneeAutocomplete from "../../ShipperConsignee/ShipperConsigneeAutocomplete";
import ShipperConsigneeDialog from '../../ShipperConsignee/ShipperConsigneeDialog';
import LoadItemTypeSelect from '../../Type/LoadItemTypeSelect';

interface IProps {
    index: number;
    item: ILoadItem;
    onChange: (newItem: ILoadItem, index: number, fieldName: string) => void;
}
const LoadItemsForm = (props: IProps) => {
    const { index, item, onChange } = props;
    const { t } = useTranslation();

    const { temperature, mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const [shipperConsigneeToggle, setShipperConsigneeToggle] = useState<boolean>(false);
    const [shipperConsigneeRefresh, setShipperConsigneeRefresh] = useState<boolean>(false);
    const [shipperConsignee, setShipperConsignee] = useState<ILoadShipperConsigneeResponseDto | null>(null);

    useEffect(() => {
        if (!item.shipperConsigneeId) {
            setShipperConsignee(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    const onShipperConsigneeToggleHandler = useCallback(() => {
        setShipperConsigneeToggle(shipperConsigneeToggle => !shipperConsigneeToggle);
    }, []);

    const onSubmitShipperConsigneeHandler = useCallback((entityId?: string) => {
        if (entityId) {
            const newItem: ILoadItem = { ...item, shipperConsigneeId: entityId };
            setShipperConsigneeRefresh(shipperConsigneeRefresh => !shipperConsigneeRefresh);
            onChange(newItem, index, 'shipperConsigneeId');
        }
    }, [index, item, onChange]);

    const onShipperConsigneeInitHandler = useCallback((value: ILoadShipperConsigneeResponseDto | null) => {
        if (value) {
            const showTime: boolean = (value && value.appointments === ELoadAppointmentType.YES) || false;

            const newItem: ILoadItem = {
                ...item,
                shipperConsigneeId: value ? value.uuid : '',
                showTime: value ? showTime : false
            };

            setShipperConsignee(value);
            onChange(newItem, index, 'shipperConsigneeId');
        }
    }, [index, item, onChange]);

    const onShipperConsigneeChangeHandler = useCallback((value: ILoadShipperConsigneeResponseDto | null) => {
        const showTime: boolean = (value && value.appointments === ELoadAppointmentType.YES) || false;

        const newItem: ILoadItem = {
            ...item,
            shipperConsigneeId: value ? value.uuid : '',
            showTime: value ? showTime : false,
            time: undefined
        };

        setShipperConsignee(value);
        onChange(newItem, index, 'shipperConsigneeId');
    }, [index, item, onChange]);

    const onDescriptionChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const newItem: ILoadItem = { ...item, description: value };
        onChange(newItem, index, 'description');
    }, [index, item, onChange]);

    const onNotesChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const newItem: ILoadItem = { ...item, notes: value };
        onChange(newItem, index, 'notes');
    }, [index, item, onChange]);

    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: ELoadItemType = event.target.value as ELoadItemType;
        const newItem: ILoadItem = { ...item, type: value };
        onChange(newItem, index, 'type');
    }, [index, item, onChange]);

    const onFragilityChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: EFragilityLevel = event.target.value as EFragilityLevel;
        const newItem: ILoadItem = { ...item, fragility: value };
        onChange(newItem, index, 'fragility');
    }, [index, item, onChange]);

    const onHighValueChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;
        const newItem: ILoadItem = { ...item, highValue: value };
        onChange(newItem, index, 'highValue');
    }, [index, item, onChange]);

    const onHighValueNotesChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const newItem: ILoadItem = { ...item, highValueNotes: value };
        onChange(newItem, index, 'highValueNotes');
    }, [index, item, onChange]);

    const onTemperatureFromChangeHandler = useCallback((value?: number) => {
        const newItem: ILoadItem = { ...item, temperatureFrom: value || NaN };
        onChange(newItem, index, 'temperatureFrom');
    }, [index, item, onChange]);

    const onTemperatureToChangeHandler = useCallback((value?: number) => {
        const newItem: ILoadItem = { ...item, temperatureTo: value || NaN };
        onChange(newItem, index, 'temperatureTo');
    }, [index, item, onChange]);

    const onWeightChangeHandler = useCallback((value: number) => {
        const newItem: ILoadItem = { ...item, weight: value || NaN };
        onChange(newItem, index, 'weight');
    }, [index, item, onChange]);

    const onQuantityChangeHandler = useCallback((value: number) => {
        const newItem: ILoadItem = { ...item, quantity: value || NaN };
        onChange(newItem, index, 'quantity');
    }, [index, item, onChange]);

    const onDateChangeHandler = useCallback((value?: number) => {
        const newItem: ILoadItem = { ...item, date: value || NaN };
        onChange(newItem, index, 'date');
    }, [index, item, onChange]);

    const onTimeChangeHandler = useCallback((value?: string) => {
        const newItem: ILoadItem = { ...item, time: value };
        onChange(newItem, index, 'time');
    }, [index, item, onChange]);

    const onPalletsChangeHandler = useCallback((pallets?: IPalletTypeQuantity[]) => {
        const newItem: ILoadItem = { ...item, pallets: pallets };
        onChange(newItem, index, 'pallets');
    }, [index, item, onChange]);

    return (
        <>
            <Stack direction='row' spacing={2}>
                <Stack direction='column' spacing={2} width='100%'>
                    <Box display='flex' flexDirection='row' gap='10px' alignItems='center'>
                        <ShipperConsigneeAutocomplete
                            direction={item.direction}
                            label={t(item.direction.toString())}
                            required
                            value={item.shipperConsigneeId}
                            disableInactiveItems
                            refresh={shipperConsigneeRefresh}
                            onInit={onShipperConsigneeInitHandler}
                            onChange={onShipperConsigneeChangeHandler}
                        />

                        <Tooltip title={t(`CREATE ${item.direction}`)}>
                            <IconButton onClick={onShipperConsigneeToggleHandler}>
                                <PlaylistAddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <TextField
                        label={t('ADDRESS')}
                        required
                        slotProps={{ htmlInput: { readOnly: true } }}
                        value={shipperConsignee?.address.value || ''}
                    />

                    <Stack direction='row' spacing={2}>
                        <TextField
                            label={t('ACCEPTED HOURS')}
                            fullWidth
                            slotProps={{ htmlInput: { readOnly: true } }}
                            value={shipperConsignee?.hours || ''}
                        />

                        <TextField
                            label={t('APPT')}
                            required
                            sx={{ width: '50%' }}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            value={shipperConsignee?.appointments || ''}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <TextField
                            label={t('CONTACT NAME')}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            value={shipperConsignee?.contactName || ''}
                        />

                        <TextField
                            label={t('PHONE')}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            value={shipperConsignee?.phone || ''}
                        />
                    </Stack>
                </Stack>

                <Stack direction='column' spacing={2} width='100%'>
                    <TextField
                        label={t('DESCRIPTION')}
                        value={item.description}
                        onChange={onDescriptionChangeHandler}
                    />

                    <TextField
                        label={t(`${item.direction} NOTES`)}
                        value={item.notes}
                        onChange={onNotesChangeHandler}
                    />

                    <Stack direction='row' spacing={2}>
                        <LoadItemTypeSelect
                            label={t('TYPE')}
                            required
                            value={item.type}
                            onChange={onTypeChangeHandler}
                        />

                        <FragilityLevelSelect
                            label={t('FRAGILITY')}
                            value={item.fragility}
                            onChange={onFragilityChangeHandler}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={item.highValue}
                                onChange={onHighValueChangeHandler}
                            />}
                            label={t('HIGH VALUE')}
                            sx={{ width: '78%' }}
                        />

                        <TextField
                            label={t('HIGH VALUE NOTES')}
                            value={item.highValueNotes}
                            fullWidth
                            onChange={onHighValueNotesChangeHandler}
                        />
                    </Stack>
                </Stack>

                <Stack direction='column' spacing={2} width='100%'>
                    <Stack direction='row' spacing={2}>
                        <NumberField
                            label={t('TEMPERATURE FROM')}
                            value={item.temperatureFrom}
                            units={temperature}
                            onChange={onTemperatureFromChangeHandler}
                        />

                        <NumberField
                            label={t('TEMPERATURE TO')}
                            value={item.temperatureTo}
                            units={temperature}
                            onChange={onTemperatureToChangeHandler}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <NumberField
                            required
                            label={t('WEIGHT')}
                            allowNegative={false}
                            value={item.weight}
                            units={mass}
                            onChange={onWeightChangeHandler}
                        />

                        <NumberField
                            required
                            label={t('QUANTITY')}
                            scale={0}
                            allowNegative={false}
                            value={item.quantity}
                            onChange={onQuantityChangeHandler}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <DateField
                            label={t('DATE')}
                            required
                            fullWidth
                            size='medium'
                            value={item.date}
                            format={dateFormat}
                            onChange={onDateChangeHandler}
                        />
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <FormControlLabel
                            disabled={!item.showTime}
                            control={<Checkbox
                                checked={item.showTime}
                                readOnly
                            />}
                            label={t('SHOW TIME')}
                            sx={{ width: '75%' }}
                        />

                        <TimeField
                            label={t('TIME')}
                            hideSeconds
                            fullWidth
                            size='medium'
                            required={item.showTime}
                            disabled={!item.showTime}
                            value={item.time}
                            onChange={onTimeChangeHandler}
                        />
                    </Stack>
                </Stack>

                <Stack direction='column' spacing={2} width='100%'>
                    <PalletTypeQuantity
                        data={item.pallets}
                        maxQuantity={item.quantity}
                        onChange={onPalletsChangeHandler}
                    />
                </Stack>
            </Stack>

            {shipperConsigneeToggle &&
                <ShipperConsigneeDialog
                    open={shipperConsigneeToggle}
                    direction={item.direction}
                    onSubmitBtnClick={onSubmitShipperConsigneeHandler}
                    onCloseBtnClick={onShipperConsigneeToggleHandler}
                />
            }
        </>
    );
}
export default LoadItemsForm;