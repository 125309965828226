import { ICustomerIdNameResponseDto } from "./CustomerModels";
import { IFileDetailsResponseDto } from "./FileModel";
import { ELoadStatus } from "./LoadModels";
import { IUserShortResponseDto } from "./UserModels";

export enum EInvoiceType {
    INVOICE = 'INVOICE',
    COMPLETE_INVOICE = 'COMPLETE_INVOICE'
}

export enum EInvoiceStatus {
    NONE,
    INVOICED = 'INVOICED',
    UNPAID = 'UNPAID',
    PAID = 'PAID'
}

export interface IInvoiceResponseDto {
    uuid: string;
    loadId: string;
    idno: string;
    customer: ICustomerIdNameResponseDto;
    invoicePdf: IFileDetailsResponseDto;
    completeInvoicePdf: IFileDetailsResponseDto;
    rate: number;
    charges: number;
    totalRate: number;
    totalShipperItems: number;
    totalConsigneeItems: number;
    shipperWeight: number;
    consigneeWeight: number;
    status: ELoadStatus;
    driver: IUserShortResponseDto[];
    disparcher: IUserShortResponseDto[];
    administrator: IUserShortResponseDto[];
    createdBy: string;
    createdDate: number;
}

export interface IInvoiceFilesResponseDto {
    uuid: string;
    type: string;
    file: IFileDetailsResponseDto;
}

export interface IInvoiceSummaryResponseDto {
    count: number;
    rate: number;
}