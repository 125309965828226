import { SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { useWarehouse } from "../../../../hooks/useWarehouse";
import { EReputationLevel } from "../../../../models/CommonModels";
import { EWarehouseStatus, IWarehouseStatusReputationRequestDto, IWarehouseStatusReputationResponseDto } from "../../../../models/WarehouseModels";
import WarehouseService from "../../../../services/WarhouseService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import ReputationSelect from "../../../Base/ReputationComponent/ReputationSelect";
import StatusSelect from "../../../Base/StatusSelectComponent/StatusSelect";

const statusData: string[] = Object.keys(EWarehouseStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== EWarehouseStatus[EWarehouseStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    entity: IWarehouseStatusReputationResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const WarehouseStatusAndReputationDialog = (props: IProps) => {
    const { open, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useWarehouse();
    const formId: string = 'warehouse-status-reputation-form';

    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, handleSubmit, formState: { isValid, isDirty } } = useForm<IWarehouseStatusReputationRequestDto>({
        defaultValues: {
            status: entity.status,
            reputationLevel: entity.reputationLevel
        }
    });

    const onSubmit = useCallback((data: IWarehouseStatusReputationRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await WarehouseService.updateStatusAndReputation(entity.uuid, data);
            if (response) {
                displayNotification({ message: t('Warehouse status and reputation was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity.uuid, gridRefresh, onCloseBtnClick, onSubmitBtnClick, stepRefresh, t]);

    const validateStatusType = useCallback((value: EWarehouseStatus) => {
        return EWarehouseStatus[value] !== undefined && value !== EWarehouseStatus[EWarehouseStatus.NONE];
    }, []);

    register('status', { validate: validateStatusType });
    const onChangeStatusHandler = useCallback((event: SelectChangeEvent) => {
        setValue('status', event.target.value as EWarehouseStatus, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateReputationType = useCallback((value: EReputationLevel) => {
        return EReputationLevel[value] !== undefined && value !== EReputationLevel[EReputationLevel.NONE];
    }, []);

    register('reputationLevel', { validate: validateReputationType });
    const onChangeReputationHandler = useCallback((event: SelectChangeEvent) => {
        setValue('reputationLevel', event.target.value as EReputationLevel, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <StatusSelect
                        label={t('STATUS')}
                        required
                        data={statusData}
                        value={getValues('status')}
                        size='medium'
                        onChange={onChangeStatusHandler}
                    />

                    <ReputationSelect
                        label={t('REPUTATION')}
                        required
                        value={getValues('reputationLevel')}
                        size='medium'
                        onChange={onChangeReputationHandler}
                    />
                </Stack>
            </form>
        );
    }, [getValues, handleSubmit, onChangeReputationHandler, onChangeStatusHandler, onSubmit, t]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t('EDIT STATUS AND REPUTATION')}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default WarehouseStatusAndReputationDialog;