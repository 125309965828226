import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";

interface IProps {
    onClick: () => void;
}
const BaseTabAddBtn = (props: IProps) => {
    const { onClick } = props;

    return (
        <IconButton size='small' onClick={onClick}>
            <AddIcon fontSize='small' />
        </IconButton>
    );
}
export default BaseTabAddBtn;