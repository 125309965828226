import { Autocomplete, AutocompleteRenderInputParams, Box, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty } from "../../helpers/generalHelper";
import { ITransportAutocompleteResponseDto } from "../../models/TransportModels";
import TransportService from "../../services/TransportService";
import AvatarComponent from "../Base/AvatarComponent/AvatarComponent";

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    disabled?: boolean;
    size?: 'medium' | 'small';
    onInit?: (value: ITransportAutocompleteResponseDto | null) => void;
    onChange?: (newValue: ITransportAutocompleteResponseDto | null) => void;
}
const TransportAutocomplete = (props: IProps) => {
    const {
        label = 'Transport', required = false, value, disabled,
        size = 'medium', onInit, onChange,
    } = props;

    const [data, setData] = useState<ITransportAutocompleteResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<ITransportAutocompleteResponseDto | null>(null);

    const sortedOptions = useMemo((): ITransportAutocompleteResponseDto[] => {
        return [...data].sort((value1, value2) => {
            const valueA = `${value1.brand}${value1.model}${value1.year}`;
            const valueB = `${value2.brand}${value2.model}${value2.year}`;
            return valueA.localeCompare(valueB);
        });
    }, [data]);

    useEffect(() => {
        if (isEmpty(data)) {
            (async () => {
                const [, response] = await TransportService.fetchAutocomplete();
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [data]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: ITransportAutocompleteResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }

            if (onInit) {
                onInit(selectedValue);
            }
        }
    }, [value, data, onInit, selectedValue]);

    const onChangeHandler = useCallback((event: any, newValue: ITransportAutocompleteResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }

    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: ITransportAutocompleteResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', gap: '20px' }}>
                <Box width={'30px'}>
                    <AvatarComponent
                        data={item.avatar}
                        width={30}
                        height={30}
                        defaultValue={item.brand}
                    />
                </Box>

                <Box>
                    <Typography variant={'body2'}>{`${item.brand} ${item.model} ${item.year}`.toUpperCase()}</Typography>
                    <Typography variant='caption'>
                        <Divider />
                        {`${item.licensePlate} | #${item.transportNumber}`.toUpperCase()}
                    </Typography>
                </Box>
            </div>
        );
    }, []);

    const onBuildOptionLabel = useCallback((item: ITransportAutocompleteResponseDto) => {
        return `${item.brand} ${item.model} ${item.year} | ${item.licensePlate} | ${item.transportNumber}`.toUpperCase();
    }, []);

    const onBuildOptionKey = useCallback((item: ITransportAutocompleteResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={sortedOptions}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
        />
    );
}
export default TransportAutocomplete;