import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from "@mui/material";

interface IProps {
    id: string;
    label: string;
    closeable: boolean;
    onClick: (id: string) => void;
}
const BaseTabCloseBtn = (props: IProps) => {
    const { id, label, closeable, onClick } = props;

    return (
        <Box display='flex' alignItems='center' gap={1}>
            {label}
            {closeable && (
                <IconButton
                    size='small'
                    component='div'
                    onClick={(event) => { event.stopPropagation(); onClick(id) }}
                >
                    <CloseIcon fontSize='small' />
                </IconButton>
            )}
        </Box>
    );
}
export default BaseTabCloseBtn;