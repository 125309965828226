import { InputAdornment, TextField } from "@mui/material";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { generateNumber } from "../../../helpers/generalHelper";

const style: CSSProperties = {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    width: '30px',
    height: '30px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: '0px',
    borderRadius: '2px',
    boxShadow: 'rgba(50, 50, 93, 0.11) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px 1px 3px'
};

const defauldColor: string = '#000000';
const randomColors: string[] = [
    '#F08080', '#f48251', '#d7bde2', '#5dade2', '#73c6b6', '#f7dc6f', '#e59866',
    '#e5e7e9', '#d5dbdb', '#85929e', '#7b241c', '#16a085', '#979a9a', '#ba4a00'
];

interface IProps {
    value?: string;
    required?: boolean;
    label?: string;
    size?: 'medium' | 'small';
    random?: boolean;
    onChange?: (newColor: string) => void;
}
const ColorPickerField = (props: IProps) => {
    const { value, label = 'Color', required = false, size = 'medium', random = false, onChange } = props;
    const [color, setColor] = useState<string>(defauldColor);

    const changeValue = useCallback((value: string) => {
        if (onChange) {
            onChange(value);
        }
        setColor(value);
    }, [onChange]);

    const onChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        changeValue(value);
    }, [changeValue]);

    useEffect(() => {
        let selectedColor: string = defauldColor;
        if (value) {
            selectedColor = value;
        } else if (random) {
            const index: number = generateNumber(0, randomColors.length - 1);
            selectedColor = randomColors[index];
        }
        changeValue(selectedColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TextField
            value={color}
            label={label}
            size={size}
            required={required}
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <input
                                type='color'
                                onChange={onChangeHandler}
                                style={style}
                                value={color}
                            />
                        </InputAdornment>
                    ),
                    readOnly: true
                }
            }}
        />
    );
}
export default ColorPickerField;