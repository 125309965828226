import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { SettlementActions } from "../store/reducers/settlementSlice";

export const useSettlement = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(SettlementActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(SettlementActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(SettlementActions.applyFilter(criteria));
    };

    return { resetState, gridRefresh, applyFilter } as const;
};