import BasePage from '../components/Base/BasePageComponent/BasePage';
import SettlementGrid from '../components/SettlementModule/SettlementGrid';
import { useSettlement } from '../hooks/useSettlement';

const SettlementPage = () => {
    const { resetState, applyFilter } = useSettlement();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<SettlementGrid />}
            stepSection={<></>}
        />
    );
}
export default SettlementPage;