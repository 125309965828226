import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generateUniqueId } from '../../../helpers/generalHelper';
import { isBlank } from '../../../helpers/textHelper';
import { IPalletTypeAutocompleteResponseDto, IPalletTypeQuantity, IPalletTypeResponseDto } from '../../../models/LoadModels';
import { RootState } from "../../../store/store";
import BaseDialog from '../../Base/BaseDialogComponent/BaseDialog';
import NumberField from "../../Base/NumberFieldComponent/NumberField";
import PalletTypeAutocomplete from "../PalletType/PalletTypeAutocomplete";
import PalletTypeDialog from '../PalletType/PalletTypeDialog';

const convertAutocompleteToPallet = (value: IPalletTypeAutocompleteResponseDto): IPalletTypeResponseDto => {
    return {
        uuid: value.uuid,
        name: value.name,
        height: value.height,
        width: value.width,
        length: value.length,
        weight: value.weight
    };
};

const isValid = (entity: IPalletTypeQuantity): boolean => {
    return !isBlank(entity.pallet.uuid) && !isBlank(entity.pallet.name) && !isNaN(entity.quantity);
};

const defaultPallet = {
    uuid: '',
    name: '',
    height: NaN,
    width: NaN,
    length: NaN,
    weight: NaN
};

interface IProps {
    open: boolean;
    maxQuantity: number;
    onCloseBtnClick: () => void;
    onSubmitBtnClick: (entity: IPalletTypeQuantity) => void;
}
const PalletTypeQuantityDialog = (props: IProps) => {
    const { open, maxQuantity, onCloseBtnClick, onSubmitBtnClick } = props;
    const { t } = useTranslation();
    const { mass, size } = useSelector((state: RootState) => state.preferenceSlice.global);

    const [palletToggle, setPalletToggle] = useState<boolean>(false);
    const [palletRefresh, setPalletRefresh] = useState<boolean>(false);
    const [entity, setEntity] = useState<IPalletTypeQuantity>({
        uuid: generateUniqueId(),
        pallet: { ...defaultPallet },
        quantity: maxQuantity
    });

    const onSubmit = useCallback(() => {
        onSubmitBtnClick(entity);
    }, [entity, onSubmitBtnClick]);

    const onPalletChangeHandler = useCallback((value: IPalletTypeAutocompleteResponseDto | null) => {
        if (value) {
            const pallet: IPalletTypeResponseDto = convertAutocompleteToPallet(value);
            const newValue: IPalletTypeQuantity = { ...entity, pallet: pallet };
            setEntity(newValue);
        } else {
            const newValue: IPalletTypeQuantity = { ...entity, pallet: { ...defaultPallet } };
            setEntity(newValue);
        }
    }, [entity]);

    const onPalletInitHandler = useCallback((value: IPalletTypeAutocompleteResponseDto | null) => {
        if (value) {
            const pallet: IPalletTypeResponseDto = convertAutocompleteToPallet(value);
            const newValue: IPalletTypeQuantity = { ...entity, pallet: pallet };
            setEntity(newValue);
        }
    }, [entity]);

    const onQuantityChangeHandler = useCallback((value: number) => {
        const newValue: IPalletTypeQuantity = { ...entity, quantity: value };
        setEntity(newValue);
    }, [entity]);

    const onPalletToggleHandler = useCallback(() => {
        setPalletToggle(palletToggle => !palletToggle);
    }, []);

    const onSubmitPalletHandler = useCallback((entityId?: string) => {
        if (entityId) {
            const newValue: IPalletTypeQuantity = { ...entity, pallet: { ...defaultPallet, uuid: entityId } };
            setEntity(newValue);
            setPalletRefresh(palletRefresh => !palletRefresh);
        }
    }, [entity]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={2}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                    <PalletTypeAutocomplete
                        label={t('PALLET')}
                        required
                        sizeType={size}
                        massType={mass}
                        showDetails
                        refresh={palletRefresh}
                        value={entity.pallet.uuid}
                        onInit={onPalletInitHandler}
                        onChange={onPalletChangeHandler}
                    />

                    <Tooltip title={t('CREATE PALLET')}>
                        <IconButton onClick={onPalletToggleHandler}>
                            <PlaylistAddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>

                <NumberField
                    required
                    label={t('QUANTITY')}
                    value={entity.quantity}
                    scale={0}
                    allowNegative={false}
                    max={maxQuantity}
                    onChange={onQuantityChangeHandler}
                />
            </Stack>
        );
    }, [
        entity.pallet.uuid, entity.quantity, mass, maxQuantity, onPalletChangeHandler,
        onPalletToggleHandler, onQuantityChangeHandler, palletRefresh, size, t, onPalletInitHandler
    ]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('SAVE')} arrow>
                <span>
                    <Button
                        variant='contained'
                        disabled={!isValid(entity)}
                        size='small'
                        startIcon={<SaveAsIcon />}
                        onClick={onSubmit}
                    >
                        {t('SAVE')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [entity, onSubmit, t]);

    return (
        <>
            <BaseDialog
                open={open}
                title={t('ADD PALLET(S)')}
                maxWidth={'xs'}
                buildContent={onBuildContent}
                buildRightActions={onBuildRightActions}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {palletToggle &&
                <PalletTypeDialog
                    open={palletToggle}
                    onSubmitBtnClick={onSubmitPalletHandler}
                    onCloseBtnClick={onPalletToggleHandler}
                />
            }
        </>
    );
}
export default PalletTypeQuantityDialog;