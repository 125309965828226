import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { IIdValue } from "../../../models/CommonModels";
import AddressService from "../../../services/AddressService";

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    disabled?: boolean;
    defaultValue?: string;
    onInit?: (value: IIdValue | null) => void;
    onChange?: (newValue: IIdValue | null) => void;
}
const CountryAutocomplete = (props: IProps) => {
    const { disabled, label = "Country", required = false, defaultValue, onInit, onChange, value } = props;

    const [data, setData] = useState<IIdValue[]>([]);
    const [selectedValue, setSelectedValue] = useState<IIdValue | null>(null);

    useEffect(() => {
        if (isEmpty(data)) {
            (async () => {
                const [, response] = await AddressService.fetchAllCountries();
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [data]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: IIdValue | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }
        }
    }, [data, value]);

    useEffect(() => {
        if (!isEmpty(data) && onInit) {
            let selectedValue: IIdValue | null = null;

            if (value) {
                selectedValue = data.find((item) => item.uuid === value) || null;
            } else if (!isBlank(defaultValue)) {
                selectedValue = data.find((item) => item.name.toLocaleLowerCase() === defaultValue?.toLocaleLowerCase()) || null;
            }

            onInit(selectedValue);
            setSelectedValue(selectedValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, onInit]);

    const onChangeHandler = useCallback((event: any, newValue: IIdValue | null) => {
        setSelectedValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return <TextField {...params} fullWidth label={label} required={required} />;
    }, [label, required]);

    const buildCountryData = useCallback((country: IIdValue) => {
        return country.name.toUpperCase();
    }, []);

    const onBuildRenderOption = useCallback((props: object, item: IIdValue) => {
        return (
            <div {...props} key={item.uuid} style={{ display: "flex", gap: "10px" }}>
                <Typography variant={"caption"}>{buildCountryData(item)}</Typography>
            </div>
        );
    }, [buildCountryData]);

    const onBuildOptionLabel = useCallback((item: IIdValue) => {
        return buildCountryData(item);
    }, [buildCountryData]);

    const onBuildOptionKey = useCallback((item: IIdValue) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid;
    }, []);

    return (
        <Autocomplete
            fullWidth
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
        />
    );
};
export default CountryAutocomplete;
