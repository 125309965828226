import { Box, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDateAndTimeFormat } from '../../helpers/dateHelper';
import { IFilter, IIdValue } from '../../models/CommonModels';
import { IUserShortResponseDto } from "../../models/UserModels";
import SettlementService from '../../services/SettlementService';
import { RootState } from '../../store/store';
import BaseCrudGrid from "../Base/BaseCrudGridComponent/BaseCrudGrid";
import LinkNav from "../Base/LinkComponent/LinkNav";

const SettlementGrid = () => {
    const { t } = useTranslation();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.settlementSlice.grid);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return SettlementService.fetchAllByFilter(filter);
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onUserColumnRender = useCallback((params: GridRenderCellParams) => {
        const user: IUserShortResponseDto = params.value;
        const value: string = `${user.fullName}${user.number ? ` #${user.number}` : ''}`;
        return (
            <LinkNav
                tooltip={value}
                label={value}
                url={`/users/${user.uuid}`}
            />
        );
    }, []);

    const onLoadsColumnRender = useCallback((params: GridRenderCellParams) => {
        const loads: IIdValue[] = params.value;
        return (
            <Box>
                {loads.map((load, index) =>
                    <span key={`load-${index}`}>
                        <LinkNav
                            tooltip={load.name}
                            label={load.name}
                            url={`/loads/${load.uuid}`}
                        />
                        <span>{index < loads.length - 1 && ', '}</span>
                    </span>
                )}
            </Box>
        );
    }, []);

    const onDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.value, dateFormat, timeFormat);
        return (
            <Tooltip title={dateTime}>
                <span>{dateTime}</span>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const author: string = params.value;
        return (
            <Tooltip title={author}>
                <span>{author}</span>
            </Tooltip>
        );
    }, []);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'idno',
            headerName: t('ID#'),
            width: 110,
            headerAlign: 'center',
            hideable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'driver',
            headerName: t('DRIVER'),
            width: 200,
            headerAlign: 'center',
            renderCell: onUserColumnRender
        }, {
            field: 'loads',
            headerName: t('LOADS'),
            minWidth: 200,
            flex: 1,
            headerAlign: 'center',
            renderCell: onLoadsColumnRender
        }, {
            field: 'createdDate',
            headerName: t('DATE'),
            width: 170,
            headerAlign: 'center',
            renderCell: onDateColumnRender
        }, {
            field: 'createdBy',
            headerName: t('CREATED'),
            width: 250,
            headerAlign: 'center',
            renderCell: onCreatedColumnRender
        }];
    }, [
        onCreatedColumnRender, onDateColumnRender, onLoadsColumnRender,
        onTooltipColumnRender, onUserColumnRender, t
    ]);

    return (
        <>
            <BaseCrudGrid
                gridId='settlement'
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnHide={true}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
            />
        </>
    );
}
export default SettlementGrid;