import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Divider, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { currencyFormat } from '../../../helpers/NumberHelper';
import { useNotification } from '../../../hooks/useNotification';
import { IFilter } from '../../../models/CommonModels';
import { ELoadStatus, ILoadStatusPreviewResponseDto } from '../../../models/LoadModels';
import LoadService from '../../../services/LoadService';
import BaseDialog from '../../Base/BaseDialogComponent/BaseDialog';
import StatusSelect from '../../Base/StatusSelectComponent/StatusSelect';

const EXCLUSION_RULES: Map<ELoadStatus, string[]> = new Map<ELoadStatus, string[]>([
    [ELoadStatus.NONE, []],
    [ELoadStatus.OPEN, [ELoadStatus.OPEN.toString(), ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.DELIVERED.toString(), ELoadStatus.INVOICED.toString(), ELoadStatus.UNPAID.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.PENDING, [ELoadStatus.PENDING.toString(), ELoadStatus.DELIVERED.toString(), ELoadStatus.INVOICED.toString(), ELoadStatus.UNPAID.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.IN_PROGRESS, [ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.OPEN.toString(), ELoadStatus.INVOICED.toString(), ELoadStatus.UNPAID.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.DELIVERED, [ELoadStatus.DELIVERED.toString(), ELoadStatus.OPEN.toString(), ELoadStatus.PENDING.toString(), ELoadStatus.UNPAID.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.INVOICED, [ELoadStatus.INVOICED.toString(), ELoadStatus.OPEN.toString(), ELoadStatus.PENDING.toString(), ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.PAID.toString()]],
    [ELoadStatus.UNPAID, [ELoadStatus.UNPAID.toString(), ELoadStatus.OPEN.toString(), ELoadStatus.PENDING.toString(), ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.DELIVERED.toString()]],
    [ELoadStatus.PAID, [ELoadStatus.PAID.toString(), ELoadStatus.OPEN.toString(), ELoadStatus.PENDING.toString(), ELoadStatus.IN_PROGRESS.toString(), ELoadStatus.DELIVERED.toString(), ELoadStatus.INVOICED.toString()]]
]);

const statusData: string[] = Object.keys(ELoadStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadStatus[ELoadStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    filter: IFilter;
    excludeStatuses?: string[];

    onSubmitBtnClick: () => void;
    onCloseBtnClick: () => void;
}
const LoadBulkStatusDialog = (props: IProps) => {
    const { open, filter, excludeStatuses = [], onSubmitBtnClick, onCloseBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const [fromStatus, setFromStatus] = useState<ELoadStatus>(ELoadStatus.NONE);
    const [fromStatusDisabled, setFromStatusDisabled] = useState<string[]>([]);
    const [toStatus, setToStatus] = useState<ELoadStatus>(ELoadStatus.NONE);
    const [toStatusDisabled, setToStatusDisabled] = useState<string[]>([]);

    const [statusPreview, setStatusPreview] = useState<ILoadStatusPreviewResponseDto>({ count: 0, amount: 0 });

    const statuses = useMemo((): string[] => {
        return statusData.filter(item => !excludeStatuses.includes(item));
    }, [excludeStatuses]);

    const onSubmitBtnClickHandler = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateStatusBulkByFilter(filter, fromStatus, toStatus);
            if (response) {
                displayNotification({ message: t('Load(s) status were successfully updated.') });

                onSubmitBtnClick();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, fromStatus, toStatus, t]);

    const fetchStatusBulkPreview = useCallback((fromStatus: ELoadStatus, toStatus: ELoadStatus) => {
        if (ELoadStatus[fromStatus] && fromStatus !== ELoadStatus.NONE && ELoadStatus[toStatus] && toStatus !== ELoadStatus.NONE) {
            (async () => {
                setLoading(true);
                const [error, response] = await LoadService.fetchStatusBulkPreviewByFilter(filter, fromStatus, toStatus);
                if (response) {
                    setStatusPreview(response.data.body);
                    setLoading(false);
                }

                if (error) {
                    displayNotification({ type: 'error', message: error?.message });
                    setLoading(false);
                }
            })();
        } else {
            setStatusPreview({ count: 0, amount: 0 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const onChangeFromStatusHandler = useCallback((event?: SelectChangeEvent) => {
        const value: ELoadStatus = event?.target.value as ELoadStatus;
        setToStatusDisabled(EXCLUSION_RULES.get(value) || []);
        setFromStatus(value);

        fetchStatusBulkPreview(value, toStatus);
    }, [fetchStatusBulkPreview, toStatus]);

    const onChangeToStatusHandler = useCallback((event?: SelectChangeEvent) => {
        const value: ELoadStatus = event?.target.value as ELoadStatus;
        setFromStatusDisabled(EXCLUSION_RULES.get(value) || []);
        setToStatus(value);

        fetchStatusBulkPreview(fromStatus, value);
    }, [fetchStatusBulkPreview, fromStatus]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={2}>
                <StatusSelect
                    label={t('FROM STATUS')}
                    required
                    data={statuses}
                    value={fromStatus}
                    size='medium'
                    disableItems={fromStatusDisabled}
                    onChange={onChangeFromStatusHandler}
                />

                <StatusSelect
                    label={t('TO STATUS')}
                    required
                    data={statuses}
                    value={toStatus}
                    size='medium'
                    disableItems={toStatusDisabled}
                    onChange={onChangeToStatusHandler}
                />

                <Divider />

                <Box>
                    <Box display={'flex'} gap={'5px'}>
                        <Typography variant={'body1'}>
                            <b>{t('TOTAL ELIGIBLE LOADS')}:</b>
                        </Typography>

                        <Typography variant={'body1'}>
                            {statusPreview.count}
                        </Typography>
                    </Box>

                    <Box display={'flex'} gap={'5px'}>
                        <Typography variant={'body1'}>
                            <b>{t('TOTAL RATE')}:</b>
                        </Typography>

                        <Typography variant={'body1'}>
                            {currencyFormat(statusPreview.amount)}
                        </Typography>
                    </Box>
                </Box>
            </Stack>
        );
    }, [
        fromStatus, fromStatusDisabled, onChangeFromStatusHandler,
        onChangeToStatusHandler, statusPreview.amount, statusPreview.count,
        statuses, t, toStatus, toStatusDisabled
    ]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('Update status')} arrow>
                <span>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<SaveAsIcon />}
                        onClick={onSubmitBtnClickHandler}
                        disabled={statusPreview.count === 0}
                    >
                        {t('SAVE')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [onSubmitBtnClickHandler, statusPreview.count, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('BULK STATUS EDIT')}
            maxWidth={'xs'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadBulkStatusDialog;