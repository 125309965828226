import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useUser } from "../../../hooks/useUser";
import { ECriteriaExpression, ICriteria } from "../../../models/CommonModels";
import { IRoleIdNameResponseDto } from "../../../models/RoleModules";
import { EUserStatus, IUserAutocompleteResponseDto } from "../../../models/UserModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import NumberField from "../../Base/NumberFieldComponent/NumberField";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";
import RoleAutocomplete from "../../RoleModule/RoleAutocomplete";
import UserAutocomplete from "../UserAutocomplete";

const statusData: string[] = Object.keys(EUserStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== EUserStatus[EUserStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    onClose: () => void;
}
const UserFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useUser();
    const { criterias } = useSelector((state: RootState) => state.userSlice.grid);

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [roleId, setRoleId] = useState<string>('');
    const [status, setStatus] = useState<EUserStatus | undefined>(undefined);
    const [number, setNumber] = useState<number>(NaN);
    const [supervisorId, setSupervisorId] = useState<string>('');

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        setFirstName(getValueFromCriterias('firstName', criterias) || '');
        setLastName(getValueFromCriterias('lastName', criterias) || '');
        setEmail(getValueFromCriterias('email', criterias) || '');
        setRoleId(getValueFromCriterias('authorities.uuid', criterias) || '');

        const statusStr: string | undefined = getValueFromCriterias('status', criterias);
        setStatus(statusStr as EUserStatus || EUserStatus.NONE);
        setNumber(getValueFromCriterias('number', criterias) || NaN);
        setSupervisorId(getValueFromCriterias('userResponsible.responsible.uuid', criterias) || '');
    }, [criterias, getValueFromCriterias]);

    const onChangeFirstNameHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    }, []);

    const onChangeLastNameHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    }, []);

    const onChangeEmailHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const onChangeRoleHandler = useCallback((value: IRoleIdNameResponseDto | null) => {
        setRoleId(value?.uuid || '');
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as EUserStatus);
    }, []);

    const onChangeNumberHandler = useCallback((value?: number) => {
        setNumber(value || NaN);
    }, []);

    const onChangeSupervisorHandler = useCallback((user: IUserAutocompleteResponseDto | null) => {
        setSupervisorId(user?.uuid || '');
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (firstName && !isBlank(firstName)) {
            newCriterias.push({
                property: 'firstName',
                value: firstName,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (lastName && !isBlank(lastName)) {
            newCriterias.push({
                property: 'lastName',
                value: lastName,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (email && !isBlank(email)) {
            newCriterias.push({
                property: 'email',
                value: email,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (number && !isNaN(number)) {
            newCriterias.push({
                property: 'number',
                value: number,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (roleId && !isBlank(roleId)) {
            newCriterias.push({
                property: 'authorities.uuid',
                value: roleId,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (status) {
            newCriterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (supervisorId && !isBlank(supervisorId)) {
            newCriterias.push({
                property: 'userResponsible.responsible.uuid',
                value: supervisorId,
                expression: ECriteriaExpression.EQUALS
            });
        }

        return newCriterias;
    }, [email, firstName, lastName, number, roleId, status, supervisorId]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('FIRST NAME')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 54 } }}
                        value={firstName}
                        onChange={onChangeFirstNameHandler}
                    />

                    <NumberField
                        label={t('NUMBER')}
                        value={number}
                        scale={0}
                        allowNegative={false}
                        size='small'
                        onChange={onChangeNumberHandler}
                    />

                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData}
                        value={status}
                        onChange={onChangeStatusHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('LAST NAME')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 54 } }}
                        value={lastName}
                        onChange={onChangeLastNameHandler}
                    />

                    <RoleAutocomplete
                        label={t('ROLE')}
                        value={roleId}
                        size='small'
                        onChange={onChangeRoleHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('EMAIL')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 60 } }}
                        value={email}
                        onChange={onChangeEmailHandler}
                    />

                    <UserAutocomplete
                        label={t('SUPERVISOR')}
                        size='small'
                        value={supervisorId}
                        onChange={onChangeSupervisorHandler}
                        disableInactiveItems
                    />
                </Stack>
            </Stack>
        );
    }, [
        email, firstName, lastName, onChangeEmailHandler, onChangeFirstNameHandler,
        onChangeLastNameHandler, onChangeRoleHandler, onChangeStatusHandler, roleId,
        status, t, number, onChangeNumberHandler, onChangeSupervisorHandler, supervisorId
    ]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default UserFilter;