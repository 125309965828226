import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertTimestampToDateStrWithFormatOrEmpty } from '../../../helpers/dateHelper';
import { useTransport } from '../../../hooks/useTransport';
import { ECriteriaExpression, ICriteria, IFilter } from '../../../models/CommonModels';
import { ETransportStatus, ITransportCoverFaceResponseDto } from '../../../models/TransportModels';
import TransportService from '../../../services/TransportService';
import { RootState } from '../../../store/store';
import AvatarComponent from '../../Base/AvatarComponent/AvatarComponent';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';

interface IProps {
    data: ITransportCoverFaceResponseDto;
}
const TransportStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { gridRefresh, stepRefresh } = useTransport();
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const convertDateToString = useCallback((value?: number) => {
        return convertTimestampToDateStrWithFormatOrEmpty(value, dateFormat);
    }, [dateFormat]);

    const getActiveStatusDate = useCallback((): string => {
        if (data.activeFromDate && data.activeToDate) {
            return `${convertDateToString(data.activeFromDate)} - ${convertDateToString(data.activeToDate)}`;
        }

        return data.activeFromDate ? `${t('FROM ')} ${convertDateToString(data.activeFromDate)}` : `${t('TO ')} ${convertDateToString(data.activeToDate)}`;
    }, [convertDateToString, data.activeFromDate, data.activeToDate, t]);

    const content = useMemo((): React.ReactNode => {
        return (
            <Box flex={1}>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
                    <AvatarComponent
                        data={data.avatar}
                        defaultValue={data.brand}
                    />

                    <Stack direction='column' spacing={0.5}>
                        <Typography>
                            <b>{data.brand} {data.model} {data.year}</b>
                        </Typography>

                        <Typography sx={{ fontSize: '13px', color: 'black' }}>
                            <b>{data.status.toString().replaceAll('_', ' ')}{data.status === ETransportStatus.TEMPORARY ? ` ${getActiveStatusDate()}` : ''}</b>
                        </Typography>
                    </Stack>

                    <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

                    <Stack direction='column' spacing={0.5}>
                        <Typography>
                            <b>{data.type.toString().replaceAll('_', ' ')} {'#'}{data.transportNumber}</b>
                        </Typography>

                        <Typography sx={{ fontSize: '13px' }}>
                            <b>{data.licensePlate}</b>
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        );
    }, [
        data.avatar, data.brand, data.licensePlate, data.model, data.status,
        data.transportNumber, data.type, data.year, getActiveStatusDate
    ]);

    const onFavoriteBtnClickHandler = useCallback(() => {
        (async () => {
            const ids: string[] = [data.uuid];
            if (data.favorite) {
                TransportService.removeFavorites(ids);
            } else {
                TransportService.createFavorites(ids);
            }
            gridRefresh();
            stepRefresh();
        })();
    }, [data.favorite, data.uuid, gridRefresh, stepRefresh]);

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return TransportService.downloadReport(filter);
    }, [data.uuid]);

    return (
        <BaseStepCoverFace
            content={content}

            favoriteBtnAddTooltip={t('Add to favorites')}
            favoriteBtnRemoveTooltip={t('Remove from favorites')}
            favoriteBtn={data.favorite}
            onFavoriteBtnClick={onFavoriteBtnClickHandler}

            downloadBtnTooltip={t('Download transport')}
            downloadApi={onDownloadApiHandler}
        />
    );
}
export default TransportStepCoverFace;