import { Button, IconButton, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { EButtonColor } from "../../../models/EnumGeneral";
import ButtonConfirmationDialog from "./ButtonConfirmationDialog";

export interface IProps {
    icon?: JSX.Element;
    label?: string;
    color?: EButtonColor;
    disabled?: boolean;
    tooltip?: string;
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    message?: string;
    iconBtn?: boolean;
    onConfirm: () => void;
}
const ButtonConfirmation = (props: IProps) => {
    const {
        disabled, onConfirm, tooltip, variant = 'contained', size = 'small',
        color = EButtonColor.info, icon, label, iconBtn = false, message
    } = props;

    const [dialog, setDialog] = useState<boolean>(false);

    const onDialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    return (
        <>
            < Tooltip title={tooltip} placement='top' >
                <span>
                    {
                        iconBtn
                            ?
                            <IconButton size={size} color={color} disabled={disabled} onClick={onDialogToggleHandler} >
                                {icon && icon}
                            </IconButton>
                            :
                            <Button size={size} variant={variant} color={color} disabled={disabled} onClick={onDialogToggleHandler} >
                                {icon && icon}&nbsp;{label && label}
                            </Button>
                    }
                </span>
            </Tooltip >

            {dialog &&
                <ButtonConfirmationDialog
                    open={dialog}
                    message={message}
                    onConfirm={onConfirm}
                    onClose={onDialogToggleHandler}
                />
            }
        </>
    );
}
export default ButtonConfirmation;