import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Divider, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EAuthority } from '../../../models/RoleModules';
import { IUserAutocompleteResponseDto } from "../../../models/UserModels";
import LoadService from '../../../services/LoadService';
import BaseDialog from '../../Base/BaseDialogComponent/BaseDialog';
import UserByTypeAutocomplete from "../../UserModule/UserByTypeAutocomplete";

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick: (id: string) => void;
}
const LoadIdDialog = (props: IProps) => {
    const { open, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [nextNumber, setNextNumber] = useState<number>(0);
    const [driverNumber, setDriverNumber] = useState<number>(0);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const [, response] = await LoadService.fetchNextLoadNumber();
            if (response) {
                setNextNumber(response.data.body);
            }
            setLoading(false);
        })();
    }, []);

    const onUserChangeHandler = useCallback((value: IUserAutocompleteResponseDto | null) => {
        setDriverNumber(value?.number || 0)
    }, []);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={2}>
                <TextField
                    label={t('NEXT LOAD NUMBER')}
                    required
                    value={nextNumber}
                    slotProps={{ htmlInput: { readOnly: true } }}
                />

                <UserByTypeAutocomplete
                    required
                    label={t('DRIVER')}
                    type={EAuthority.DRIVER}
                    disableInactiveItems
                    disableNoNumberItems
                    onChange={onUserChangeHandler}
                />

                <Divider />

                <Box display={'flex'} gap={'5px'}>
                    <Typography variant={'body1'}>
                        <b>{t('NEXT LOAD ID#')}:</b>
                    </Typography>

                    <Typography variant={'body1'}>
                        {nextNumber}-{driverNumber}
                    </Typography>
                </Box>
            </Stack>
        );
    }, [driverNumber, nextNumber, onUserChangeHandler, t]);

    const onSubmitBtnClickHandler = useCallback(() => {
        const id: string = `${nextNumber}-${driverNumber}`;
        onSubmitBtnClick(id);
        onCloseBtnClick();
    }, [driverNumber, nextNumber, onCloseBtnClick, onSubmitBtnClick]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('APPLY')} arrow>
                <span>
                    <Button
                        variant='contained'
                        size='small'
                        disabled={driverNumber === 0}
                        startIcon={<SaveAsIcon />}
                        onClick={onSubmitBtnClickHandler}
                    >
                        {t('APPLY')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [driverNumber, onSubmitBtnClickHandler, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('GENERATE LOAD ID#')}
            maxWidth={'xs'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadIdDialog;