import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFavoritesAuto } from "../../models/TransportModels";
import { IFavouritesLoad } from "../../models/LoadModels";
import { IFavoritesCustomer } from "../../models/CustomerModels";
import { IFavoritesWarehouse } from "../../models/WarehouseModels";
import FavoriteListService from "../../services/FavoriteListService";
import { AppDispatch, RootState } from "../store";
import { IFavoritesAudit } from "../../models/AuditModels";

interface FavoritesState {
    users: any[],
    transports: IFavoritesAuto[],
    audits: IFavoritesAudit[],
    customers: IFavoritesCustomer[],
    loads: IFavouritesLoad[],
    warehouses: IFavoritesWarehouse[]
}

const initialState: FavoritesState = {
    users: [],
    transports: [],
    audits: [],
    customers: [],
    loads: [],
    warehouses: []
}
//-----------------------------------------------------------------------------------------------//

export const addFavoritesWarehouses = createAsyncThunk<void, string[], { dispatch: AppDispatch }>(
    'favorites/addWarehouses',
    async (warehouses: string[], { dispatch }) => {

        let key = 'WAREHOUSE';
        let body: Array<string> = [];
        for (let u of warehouses) {
            body.push(u)
        }

        const [, response] = await FavoriteListService.createFavorites(body, key.toLowerCase());
        if (response) {
            dispatch(getFavoritesWarehouses())
        }
    }
);

export const addFavoritesAudits = createAsyncThunk<void, IFavoritesAudit, { dispatch: AppDispatch }>(
    'favorites/addAudits',
    async (audit: IFavoritesAudit, { dispatch }) => {

        let key = 'AUDIT';

        const [, response] = await FavoriteListService.createFavorites(audit, key.toLowerCase());
        if (response) {
            dispatch(getFavoritesAudits())
        }
    }
);

export const addFavoritesLoads = createAsyncThunk<void, string[], { dispatch: AppDispatch }>(
    'favorites/addLoads',
    async (loads: string[], { dispatch }) => {

        let key = 'LOAD';
        let body: Array<string> = [];
        for (let u of loads) {
            body.push(u)
        }

        const [, response] = await FavoriteListService.createFavorites(body, key.toLowerCase());
        if (response) {
            dispatch(getFavoritesLoads())
        }
    }
);
export const addFavoritesCustomers = createAsyncThunk<void, string[], { dispatch: AppDispatch }>(
    'favorites/addCustomers',
    async (customers: string[], { dispatch }) => {

        let key = 'CUSTOMER';
        let body: Array<string> = [];
        for (let u of customers) {
            body.push(u)
        }

        const [, response] = await FavoriteListService.createFavorites(body, key.toLowerCase());
        if (response) {
            dispatch(getFavoritesCustomers())
        }
    }
);
export const addFavoritesUsers = createAsyncThunk<void, string[], { dispatch: AppDispatch }>(
    'favorites/addUsers',
    async (users: string[], { dispatch }) => {

        let key = 'USER';
        let body: Array<string> = [];
        for (let u of users) {
            body.push(u)
        }

        const [, response] = await FavoriteListService.createFavorites(body, key.toLowerCase());
        if (response) { dispatch(getFavoritesUsers()) }
    }
);
export const addFavoritesAutos = createAsyncThunk<void, string[], { dispatch: AppDispatch }>(
    'favorites/addTransports',
    async (autos: string[], { dispatch }) => {
        let key = 'TRANSPORT';
        let body: Array<string> = [];
        for (let u of autos) {
            body.push(u)
        }
        const [, response] = await FavoriteListService.createFavorites(body, key.toLowerCase());
        if (response) { dispatch(getFavoritesTransport()) }
    }
);

//-----------------------------------------------------------------------------------------------//
export const deleteFavoritesLoads = createAsyncThunk<IFavouritesLoad[], string[], { state: RootState, dispatch: AppDispatch }>(
    'favorites/deleteLoads',
    async (loads: string[], { getState, dispatch }) => {

        let st: IFavouritesLoad[] = [...getState().favoritesSlice.loads as IFavouritesLoad[]];

        let body: string[] = [];
        for (let u of loads) { body.push(u) }

        const [, response] = await FavoriteListService.deleteFavorites(body, 'load');
        if (response) {
            dispatch(getFavoritesLoads())
        }
        return st;
    }
);

export const deleteFavoritesAudit = createAsyncThunk<IFavoritesAudit[], IFavoritesAudit[], { state: RootState, dispatch: AppDispatch }>(
    'favorites/deleteAudit',
    async (audits: IFavoritesAudit[], { getState, dispatch }) => {

        let st: IFavoritesAudit[] = [...getState().favoritesSlice.audits as IFavoritesAudit[]];

        let body: string[] = [];
        for (let u of audits) { if (u.uuid) body.push(u.uuid) }

        const [, response] = await FavoriteListService.deleteFavorites(body, 'audit');
        if (response) {
            dispatch(getFavoritesAudits())
        }
        return st;
    }
);

export const deleteFavoritesCustomers = createAsyncThunk<IFavoritesCustomer[], string[], { state: RootState, dispatch: AppDispatch }>(
    'favorites/deleteCustomers',
    async (customers: string[], { getState, dispatch }) => {

        let st: IFavoritesCustomer[] = [...getState().favoritesSlice.customers as IFavoritesCustomer[]];

        let body: string[] = [];
        for (let u of customers) { body.push(u) }

        const [, response] = await FavoriteListService.deleteFavorites(body, 'customer');
        if (response) {
            dispatch(getFavoritesCustomers())
        }
        return st;
    }
);

export const deleteFavoritesWarehouses = createAsyncThunk<IFavoritesWarehouse[], string[], { state: RootState, dispatch: AppDispatch }>(
    'favorites/deleteWarehouse',
    async (warehouses: string[], { getState, dispatch }) => {

        let st: IFavoritesWarehouse[] = [...getState().favoritesSlice.warehouses as IFavoritesWarehouse[]];

        let body: string[] = [];
        for (let u of warehouses) { body.push(u) }

        const [, response] = await FavoriteListService.deleteFavorites(body, 'warehouse');
        if (response) {
            dispatch(getFavoritesWarehouses())
        }
        return st;
    }
);


export const deleteFavoritesUsers = createAsyncThunk<any[], any[], { state: RootState, dispatch: AppDispatch }>(
    'favorites/deleteUsers',
    async (users: any[], { getState, dispatch }) => {

        let st: any[] = [...getState().favoritesSlice.users as any[]];

        let body: string[] = [];
        for (let u of users) { body.push(u.uuid) }

        const [, response] = await FavoriteListService.deleteFavorites(body, 'user');
        if (response) {
            dispatch(getFavoritesUsers())
        }
        return st;
    }
);
export const deleteFavoritesAutos = createAsyncThunk<IFavoritesAuto[], IFavoritesAuto[], { state: RootState, dispatch: AppDispatch }>(
    'favorites/deleteTransports',
    async (autos: IFavoritesAuto[], { getState, dispatch }) => {

        let st: IFavoritesAuto[] = [...getState().favoritesSlice.transports as IFavoritesAuto[]];

        let body: string[] = [];
        for (let u of autos) { body.push(u.uuid) }

        const [, response] = await FavoriteListService.deleteFavorites(body, 'transport');
        if (response) {
            dispatch(getFavoritesTransport())
        }
        return st;
    }
);

//-----------------------------------------------------------------------------------------------//
export const getFavoritesCustomers = createAsyncThunk<IFavoritesCustomer[]>(
    'favorites/getCustomers',
    async () => {

        let st: IFavoritesCustomer[] = [];
        let key = 'customer';
        const [, response] = await FavoriteListService.getFavorites(key);

        if (response) {
            let res: IFavoritesCustomer[] = response.data.body;
            for (let u of res) {
                try {
                    st.push({
                        uuid: u.uuid,
                        name: u.name,
                        phone: u.phone,
                        lastLoadDate: u.lastLoadDate,
                        status: u.status,
                        reputationLevel: u.reputationLevel,
                        reviewMark: u.reviewMark,
                        email: u.email
                    })
                }
                catch (exception_var) { } finally { }
            }
        }
        return st;
    }
);
export const getFavoritesUsers = createAsyncThunk<any[]>(
    'favorites/getUsers',
    async () => {

        let st: any[] = [];
        let key = 'USER';
        const [, response] = await FavoriteListService.getFavorites(key.toLowerCase());

        if (response) {
            let res: any[] = response.data.body;
            for (let u of res) {
                try {
                    st.push({
                        uuid: u.uuid,
                        lastName: u.lastName,
                        firstName: u.firstName,
                        email: u.email,
                        avatar: u.avatar,
                        username: u.username,
                        color: u.color
                    })
                }
                catch (exception_var) { } finally { }
            }
        }
        return st;
    }
);
export const getFavoritesTransport = createAsyncThunk<IFavoritesAuto[]>(
    'favorites/getTransports',
    async () => {
        let st: IFavoritesAuto[] = [];
        let key = 'TRANSPORT';
        const [, response] = await FavoriteListService.getFavorites(key.toLowerCase());

        if (response) {
            let res: IFavoritesAuto[] = response.data.body;
            for (let u of res) {
                try {
                    st.push({
                        brand: u.brand,
                        color: u.color,
                        licensePlate: u.licensePlate,
                        mainImage: u.mainImage,
                        model: u.model,
                        transportNumber: u.transportNumber,
                        uuid: u.uuid,
                        vin: u.vin
                    })
                }
                catch (exception_var) { } finally { }
            }
        }
        return st;
    }
);
export const getFavoritesLoads = createAsyncThunk<IFavouritesLoad[]>(
    'favorites/getLoads',
    async () => {

        let st: IFavouritesLoad[] = [];
        let key = 'LOAD';
        const [, response] = await FavoriteListService.getFavorites(key.toLowerCase());

        if (response) {
            let res: IFavouritesLoad[] = response.data.body;
            for (let u of res) {
                try {
                    st.push({
                        uuid: u.uuid,
                        customer: u.customer,
                        idno: u.idno,
                        reviewMark: u.reviewMark,
                        status: u.status
                    })
                }
                catch (exception_var) { } finally { }
            }
        }
        return st;
    }
);

export const getFavoritesAudits = createAsyncThunk<IFavoritesAudit[]>(
    'favorites/getAudits',
    async () => {

        let st: IFavoritesAudit[] = [];
        let key = 'AUDIT';
        const [, response] = await FavoriteListService.getFavorites(key.toLowerCase());

        if (response) {
            let res: IFavoritesAudit[] = response.data.body;
            for (let u of res) {
                try {
                    st.push({
                        uuid: u.uuid,
                        value: u.value,
                        name: u.name
                    })
                }
                catch (exception_var) { } finally { }
            }
        }
        return st;
    }
);

export const getFavoritesWarehouses = createAsyncThunk<IFavoritesWarehouse[]>(
    'favorites/getWarehouses',
    async () => {

        let st: IFavoritesWarehouse[] = [];
        let key = 'WAREHOUSE';
        const [, response] = await FavoriteListService.getFavorites(key.toLowerCase());

        if (response) {
            let res: IFavoritesWarehouse[] = response.data.body;
            for (let u of res) {
                try {
                    st.push({
                        uuid: u.uuid,
                        address: u.address,
                        contactName: u.contactName,
                        email: u.email,
                        name: u.name,
                        phone: u.phone,
                        reputationLevel: u.reputationLevel,
                        status: u.status,
                        types: u.types
                    })
                }
                catch (exception_var) { } finally { }
            }
        }
        return st;
    }
);
//-----------------------------------------------------------------------------------------------//
export const favoritesSlice = createSlice({
    name: 'favorites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteFavoritesUsers.fulfilled, (state, action) => {
                state.users = action.payload;
            })
            .addCase(deleteFavoritesCustomers.fulfilled, (state, action) => {
                state.customers = action.payload;
            })
            .addCase(deleteFavoritesAutos.fulfilled, (state, action) => {
                state.transports = action.payload;
            })
            .addCase(deleteFavoritesLoads.fulfilled, (state, action) => {
                state.loads = action.payload;
            })
            .addCase(deleteFavoritesAudit.fulfilled, (state, action) => {
                state.audits = action.payload;
            })
            .addCase(getFavoritesUsers.fulfilled, (state, action) => {
                state.users = action.payload;
            })
            .addCase(getFavoritesAudits.fulfilled, (state, action) => {
                state.audits = action.payload;
            })
            .addCase(getFavoritesTransport.fulfilled, (state, action) => {
                state.transports = action.payload;
            })
            .addCase(getFavoritesCustomers.fulfilled, (state, action) => {
                state.customers = action.payload;
            })
            .addCase(getFavoritesLoads.fulfilled, (state, action) => {
                state.loads = action.payload;
            })
            .addCase(getFavoritesWarehouses.fulfilled, (state, action) => {
                state.warehouses = action.payload;
            })
    }
})

export default favoritesSlice.reducer;