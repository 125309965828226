import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoBox from '../../../Base/NotificationComponent/InfoBox';

const data: { title: string, message: string }[] = [{
    title: 'OPEN',
    message: 'indicates that the LOAD is not yet ready and is currently in the process of being prepared.'
}, {
    title: 'PENDING',
    message: 'indicates that the LOAD is ready to start.'
}, {
    title: 'IN PROGRESS',
    message: 'indicates that the LOAD has not yet been completed.'
}, {
    title: 'DELIVERED',
    message: 'indicates that the LOAD has been delivered. There is a RATE CONFIRMATION and at least one BILL OF LADING.'
}, {
    title: 'INVOICED',
    message: 'indicates that all required proofs are present and an invoice has been issued.'
}, {
    title: 'UNPAID',
    message: 'indicates that all documents have been sent to the payment provider, and the funds are expected to be received.'
}, {
    title: 'PAID',
    message: 'indicates that the payment provider has transferred the funds.'
}];

interface IProps {
    selectItems?: string[];
    excludeItems?: string[];
}
const LoadStatusLegend = (props: IProps) => {
    const { selectItems = [], excludeItems = [] } = props;
    const { t } = useTranslation();

    return (
        <Stack spacing={2}>
            {data.filter((item) => !excludeItems.includes(item.title)).map((item, index) =>
                <InfoBox
                    key={`legend-${index}`}
                    title={t(item.title)}
                    message={t(item.message)}
                    colorRed={selectItems.includes(item.title)}
                />
            )}
        </Stack>
    );
}
export default LoadStatusLegend;