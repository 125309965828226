import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserProfileResponseDto } from "../../models/UserProfileModels";
import { EAuthority } from "../../models/RoleModules";

interface UserProfileState {
    profile: IUserProfileResponseDto;
};

const initialState: UserProfileState = {
    profile: {
        firstName: '',
        lastName: '',
        email: '',
        color: '',
        birthday: NaN,
        role: {
            name: EAuthority.NONE,
            privileges: []
        }
    }
};

export const UserProfileSlice = createSlice({
    name: 'userProfileSlice',
    initialState: initialState,
    reducers: {
        update(state: UserProfileState, action: PayloadAction<IUserProfileResponseDto>) {
            state.profile = action.payload;
        }
    }
});

export const UserProfileActions = UserProfileSlice.actions;
export const UserProfileReducer = UserProfileSlice.reducer;