import { Divider } from "@mui/material";
import { buildGoogleMapsLink } from "../../../helpers/AddressHelper";
import { IWarehouseResponseDto } from "../../../models/WarehouseModels";
import LinkNav from "../../Base/LinkComponent/LinkNav";

interface IProps {
    data: IWarehouseResponseDto;
}
const WarehouseMapsMarker = (props: IProps) => {
    const { data } = props;
    const addressLink: string = buildGoogleMapsLink(data.address.latitude, data.address.longitude);

    return (
        <div>
            <LinkNav
                label={data.address.fullAddress}
                url={addressLink}
                rel='noreferrer'
            />

            <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />

            <div>
                {data.types.map(item => item.name.replaceAll('_', ' ').toUpperCase()).join(' | ')}
            </div>
        </div>
    );
}
export default WarehouseMapsMarker;