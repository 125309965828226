import AutoModeIcon from '@mui/icons-material/AutoMode';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Checkbox, FormControlLabel, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationUrl, removeEmptyFields } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { useLoad } from '../../../hooks/useLoad';
import { useNotification } from "../../../hooks/useNotification";
import { ICustomerAutocompleteResponseDto } from "../../../models/CustomerModels";
import { ILoadOverviewResponseDto, ILoadRequestDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import CurrencyField from '../../Base/CurrencyFieldComponent/CurrencyField';
import CustomerAutocomplete from "../../CustomerModule/CustomerAutocomplete";
import CustomerDialog from '../../CustomerModule/Grid/CustomerDialog';
import LoadIdDialog from './LoadIdDialog';

interface IProps {
    open: boolean;
    entity?: ILoadOverviewResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadDialog = (props: IProps) => {
    const { open, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useLoad();
    const formId: string = 'load-form';

    const [loading, setLoading] = useState(false);
    const [customerToggle, setCustomerToggle] = useState<boolean>(false);
    const [numberToggle, setNumberToggle] = useState<boolean>(false);
    const [customerRefresh, setCustomerRefresh] = useState<boolean>(false);

    const { register, setValue, getValues, handleSubmit, setError, formState: { isValid, isDirty, errors } } = useForm<ILoadRequestDto>({
        defaultValues: {
            customerId: entity ? entity?.customer.uuid : '',
            assignLater: entity ? entity.assignLater : false,
            idno: entity ? entity?.idno || '' : '',
            rate: entity ? entity?.rate : NaN,
            flatRate: entity ? entity?.flatRate : NaN
        }
    });

    const updateData = useCallback((uuid: string, data: ILoadRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('Load was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: ILoadRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.create(data);
            if (response) {
                displayNotification({ message: t('Load was successfully created.') });

                const uuid = response.data.response.entityId;
                const newUrl: string = normalizeNavigationUrl(uuid);
                navigate(`/${newUrl}`);

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: ILoadRequestDto) => {
        const normalisedData: ILoadRequestDto = removeEmptyFields(data) as unknown as ILoadRequestDto;
        if (entity) {
            updateData(entity.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, entity, updateData]);

    const validateCustomerField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('customerId', { validate: validateCustomerField });
    const onCustomerChangeHandler = useCallback((value: ICustomerAutocompleteResponseDto | null) => {
        setValue('customerId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onCustomerToggleHandler = useCallback(() => {
        setCustomerToggle(customerToggle => !customerToggle);
    }, []);

    const onSubmitCustomerHandler = useCallback((entityId?: string) => {
        if (entityId) {
            setValue('customerId', entityId);
            setCustomerRefresh(customerRefresh => !customerRefresh);
        }
    }, [setValue]);

    register('assignLater');
    const onAssignLaterChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;

        if (value) {
            setValue('idno', '');
        }

        setValue('assignLater', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateIdno = useCallback((value?: string) => {
        if (getValues('assignLater')) {
            return true;
        }

        if (!value || isBlank(value)) {
            return false;
        }

        const regex = /^\d+-\d+$/;
        if (!regex.test(value)) {
            return false;
        }

        if (!entity || (entity?.idno !== value)) {
            (async () => {
                const [, response] = await LoadService.isIdnoAvailable(value);
                if (response) {
                    const available: boolean = response.data.body;
                    if (!available) {
                        const message: string = t('The load ID# is in use.');
                        setError('idno', { message: message });
                        return false;
                    }
                }
            })();
        }

        return true;
    }, [entity, getValues, setError, t]);

    const onIdnoChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value || '';
        const regex = /^\d+(-\d*)?$/;

        if (value === '' || regex.test(value)) {
            setValue('idno', value, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }, [setValue]);

    const validateRateField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('rate', { validate: validateRateField });
    const onRateChangeHandler = useCallback((value: number) => {
        setValue('rate', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('flatRate');
    const onFlatRateChangeHandler = useCallback((value: number) => {
        setValue('flatRate', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onNumberToggleHandler = useCallback(() => {
        setNumberToggle(numberToggle => !numberToggle);
    }, []);

    const onGenerateIdHandler = useCallback((id: string) => {
        setValue('idno', id, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <Box display='flex' flexDirection='row' gap='10px' alignItems='center'>
                        <CustomerAutocomplete
                            required
                            label={t('CUSTOMER')}
                            showAddress
                            disableInactiveItems
                            refresh={customerRefresh}
                            value={getValues('customerId')}
                            onChange={onCustomerChangeHandler}
                        />

                        <Tooltip title={t('CREATE CUSTOMER')}>
                            <IconButton onClick={onCustomerToggleHandler}>
                                <PlaylistAddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <FormControlLabel
                        control={<Checkbox
                            checked={getValues('assignLater')}
                            onChange={onAssignLaterChangeHandler}
                        />}
                        label={t('ASSIGN LATER')}
                    />

                    <Box display='flex' flexDirection='row' gap='10px' alignItems='center'>
                        <TextField
                            {...register('idno', {
                                required: !getValues('assignLater'),
                                maxLength: 25,
                                minLength: 1,
                                validate: (value) => validateIdno(value)
                            })}
                            required={!getValues('assignLater')}
                            autoComplete='off'
                            label={t('ID#')}
                            value={getValues('idno')}
                            slotProps={{ htmlInput: { minLength: 1, maxLength: 25 } }}
                            disabled={getValues('assignLater')}
                            fullWidth
                            onChange={onIdnoChangeHandler}
                            error={!!errors.idno}
                            helperText={errors.idno?.message}
                        />

                        <Tooltip title={t('GENERATE ID#')}>
                            <IconButton disabled={getValues('assignLater')} onClick={onNumberToggleHandler}>
                                <AutoModeIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Stack direction={'row'} spacing={2}>
                        <CurrencyField
                            label={t('RATE')}
                            required
                            allowNegative={false}
                            value={getValues('rate')}
                            onChange={onRateChangeHandler}
                        />

                        <CurrencyField
                            label={t('FLAT RATE')}
                            allowNegative={false}
                            value={getValues('flatRate') || NaN}
                            onChange={onFlatRateChangeHandler}
                        />
                    </Stack>
                </Stack>
            </form >
        );
    }, [
        customerRefresh, errors.idno, getValues, handleSubmit, onAssignLaterChangeHandler,
        onCustomerChangeHandler, onCustomerToggleHandler, onFlatRateChangeHandler,
        onIdnoChangeHandler, onRateChangeHandler, onSubmit, register, t, validateIdno,
        onNumberToggleHandler
    ]);

    return (
        <>
            <BaseCrudDialog
                loading={loading}
                open={open}
                title={t(`${entity ? 'EDIT' : 'CREATE'} LOAD`)}
                maxWidth={'xs'}
                formId={formId}
                buildContent={onBuildContent}
                saveBtnDisabled={!isValid || !isDirty}
                saveBtnLabel={t('SAVE')}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {customerToggle &&
                <CustomerDialog
                    open={customerToggle}
                    statusActive
                    onSubmitBtnClick={onSubmitCustomerHandler}
                    onCloseBtnClick={onCustomerToggleHandler}
                />
            }

            {numberToggle &&
                <LoadIdDialog
                    open={numberToggle}
                    onSubmitBtnClick={onGenerateIdHandler}
                    onCloseBtnClick={onNumberToggleHandler}
                />
            }
        </>
    );
}
export default LoadDialog;