import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Breakpoint, Button, Tooltip } from "@mui/material";
import { useCallback } from 'react';
import BaseDialog from "../BaseDialogComponent/BaseDialog";

interface IProps {
    loading?: boolean;
    open: boolean;

    title: string;

    maxWidth?: Breakpoint;

    contentPadding?: string;
    actionsPadding?: string;

    formId: string;
    buildContent: () => any;

    onCloseBtnClick: () => void;
    closeBtnLabel?: string;

    saveBtnDisabled: boolean;
    saveBtnLabel?: string;
}
const BaseCrudDialog = (props: IProps) => {
    const {
        open, loading, buildContent, saveBtnDisabled, title, maxWidth, formId,
        onCloseBtnClick, closeBtnLabel, saveBtnLabel = 'SAVE', contentPadding,
        actionsPadding
    } = props;

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={saveBtnLabel} arrow>
                <span>
                    <Button
                        form={formId}
                        type='submit'
                        variant='contained'
                        disabled={saveBtnDisabled}
                        size='small'
                        startIcon={<SaveAsIcon />}>
                        {saveBtnLabel}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [formId, saveBtnDisabled, saveBtnLabel]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            maxWidth={maxWidth}
            title={title}
            contentPadding={contentPadding}
            actionsPadding={actionsPadding}
            buildContent={buildContent}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={closeBtnLabel}
            buildRightActions={onBuildRightActions}
        />
    );
}
export default BaseCrudDialog;