import DescriptionIcon from '@mui/icons-material/Description';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FolderIcon from '@mui/icons-material/Folder';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationHomeUrl } from "../../../helpers/generalHelper";
import { useNotification } from "../../../hooks/useNotification";
import { useTransport } from "../../../hooks/useTransport";
import { IStepItem } from "../../../models/CommonModels";
import { ITransportCoverFaceResponseDto } from "../../../models/TransportModels";
import TransportService from "../../../services/TransportService";
import { RootState } from "../../../store/store";
import BaseStep from "../../Base/BaseStepComponent/BaseStep";
import TransportDescriptionStep from "./Description/TransportDescriptionStep";
import TransportDocumentsStep from "./Documents/TransportDocumentsStep";
import TransportImagesStep from "./Images/TransportImagesStep";
import TransportIssuesStep from "./Issues/TransportIssuesStep";
import TransportOverviewStep from "./Overview/TransportOverviewStep";
import TransportStatusStep from "./Status/TransportStatusStep";
import TransportStepCoverFace from "./TransportStepCoverFace";

const TransportStep = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const navigate = useNavigate();
    const { refresh } = useSelector((state: RootState) => state.transportSlice.step);
    const { gridRefresh } = useTransport();

    const onRetrieveDataApiHandler = useCallback((id: string) => {
        return TransportService.fetchCoverFaceData(id);
    }, []);

    const onBuildCoverFaceHandler = useCallback((data: ITransportCoverFaceResponseDto): React.ReactNode => {
        return <TransportStepCoverFace data={data} />;
    }, []);

    const onBuildItemsHandler = useCallback((data: ITransportCoverFaceResponseDto): IStepItem[] => {
        const id: string = data.uuid;
        const readonly: boolean = data.readonly;
        return [{
            title: t('OVERVIEW'),
            content: <TransportOverviewStep transportId={id} readonly={readonly} />,
            icon: <FileOpenIcon />
        }, {
            title: t('IMAGES'),
            content: <TransportImagesStep transportId={id} readonly={readonly} />,
            icon: <PermMediaIcon />
        }, {
            title: t('DESCRIPTION'),
            content: <TransportDescriptionStep transportId={id} />,
            icon: <DescriptionIcon />
        }, {
            title: t('TASKS'),
            content: <TransportIssuesStep transportId={id} />,
            icon: <PlaylistAddCheckIcon />
        }, {
            title: t('DOCUMENTS'),
            content: <TransportDocumentsStep transportId={id} />,
            icon: <FolderIcon />
        }, {
            title: t('STATUS'),
            content: <TransportStatusStep transportId={id} readonly={readonly} />,
            icon: <SwapHorizontalCircleIcon />
        }];
    }, [t]);

    const onDeleteBtnClickHandler = useCallback((uuid: string) => {
        (async () => {
            const [error] = await TransportService.remove(uuid);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
            } else {
                const newUrl: string = normalizeNavigationHomeUrl(true);
                navigate(`/${newUrl}`);
                displayNotification({ message: t('Transport was successfully deleted.') });
                gridRefresh();
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <BaseStep
            retrieveDataApi={onRetrieveDataApiHandler}
            refresh={refresh}
            buildCoverFace={onBuildCoverFaceHandler}
            buildItems={onBuildItemsHandler}
            onDeleteBtnClick={onDeleteBtnClickHandler}
            deleteBtnTooltip={t('Delete item')}
            closeBtnTooltip={t('Close and return to the main list')}
        />
    );
}
export default TransportStep;