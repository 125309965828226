import { Autocomplete, AutocompleteRenderInputParams, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../helpers/generalHelper";
import { ELoadItemDirection, ELoadShipperConsigneeStatus, ILoadShipperConsigneeResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    size?: 'medium' | 'small';
    disableInactiveItems?: boolean;
    direction: ELoadItemDirection;
    refresh?: boolean;
    onInit?: (value: ILoadShipperConsigneeResponseDto | null) => void;
    onChange?: (newValue: ILoadShipperConsigneeResponseDto | null) => void;
}
const ShipperConsigneeAutocomplete = (props: IProps) => {
    const {
        size = 'medium', direction, label = direction.toString(), required = false,
        onChange, value, disableInactiveItems = false, refresh, onInit
    } = props;

    const [data, setData] = useState<ILoadShipperConsigneeResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<ILoadShipperConsigneeResponseDto | null>(null);

    useEffect(() => {
        setData([]);
        setSelectedValue(null);
        (async () => {
            const [, response] = await LoadService.fetchShipperConsigneeAutocomplete(direction);
            if (response) {
                setData(response.data.body);
            }
        })();
    }, [direction, refresh]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const newSelectedValue: ILoadShipperConsigneeResponseDto | null = data.find((item) => item.uuid === value) || null;

                if (onInit) {
                    onInit(newSelectedValue);
                }

                setSelectedValue(newSelectedValue);
            } else {
                setSelectedValue(null);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, data]);

    const onChangeHandler = useCallback((event: any, newValue: ILoadShipperConsigneeResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: ILoadShipperConsigneeResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography variant='body2'>{item.name}</Typography>
                <Typography variant='caption' sx={{ fontSize: 10 }}>
                    <Divider />
                    {item.address.value}
                </Typography>
            </div>
        );
    }, []);

    const onBuildOptionLabel = useCallback((item: ILoadShipperConsigneeResponseDto) => {
        return item.name;
    }, []);

    const onBuildOptionKey = useCallback((item: ILoadShipperConsigneeResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    const getOptionDisabledHandler = useCallback((item: ILoadShipperConsigneeResponseDto) => {
        return disableInactiveItems
            ? item.status === ELoadShipperConsigneeStatus.INACTIVE
            : false;
    }, [disableInactiveItems]);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionDisabled={getOptionDisabledHandler}
        />
    );
}
export default ShipperConsigneeAutocomplete;