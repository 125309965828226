import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { Box, Button, Divider, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { isBlank } from '../../../helpers/textHelper';
import { useLoad } from '../../../hooks/useLoad';
import { ECriteriaExpression, ICriteria } from '../../../models/CommonModels';
import { ELoadItemDirection, ELoadShipperConsigneeStatus } from '../../../models/LoadModels';
import BaseDialog from "../../Base/BaseDialogComponent/BaseDialog";
import StatusSelect from '../../Base/StatusSelectComponent/StatusSelect';
import ShipperConsigneeDirectionSelect from './ShipperConsigneeDirectionSelect';
import ShipperConsigneeGrid from './ShipperConsigneeGrid';

const legendStyle: {} = {
    fontSize: '0.8rem',
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    letterSpacing: '0.00938em',
    cursor: 'default'
};

const fieldSetStyle: {} = {
    borderColor: 'rgb(239 239 239 / 60%)',
    borderRadius: '3px',
    padding: '10px 15px 15px 15px',
    width: '100%'
};

const statusData: string[] = Object.keys(ELoadShipperConsigneeStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadShipperConsigneeStatus[ELoadShipperConsigneeStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
}
const ShipperConsigneeManagementDialog = (props: IProps) => {
    const { open, onCloseBtnClick } = props;
    const { t } = useTranslation();
    const { shipperConsigneeGridApplyFilter } = useLoad();

    const [name, setName] = useState<string>('');
    const [type, setType] = useState<ELoadItemDirection>();
    const [status, setStatus] = useState<ELoadShipperConsigneeStatus>();

    const onChangeNameHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }, []);

    const onChangeTypeHandler = useCallback((event: SelectChangeEvent) => {
        setType(event.target.value as ELoadItemDirection)
    }, []);

    const onChangeStatusHandler = useCallback((event: SelectChangeEvent) => {
        setStatus(event.target.value as ELoadShipperConsigneeStatus)
    }, []);

    const onApplyHandler = useCallback(() => {
        const criterias: ICriteria[] = [];

        if (name && !isBlank(name)) {
            criterias.push({
                property: 'name',
                value: name,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (type) {
            criterias.push({
                property: 'direction',
                value: type,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (status) {
            criterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        shipperConsigneeGridApplyFilter(criterias);
    }, [name, shipperConsigneeGridApplyFilter, status, type]);

    const onResetHandler = useCallback(() => {
        setName('');
        setType(undefined);
        setStatus(undefined);

        shipperConsigneeGridApplyFilter([]);
    }, [shipperConsigneeGridApplyFilter]);

    const onBuildContent = useCallback(() => {
        return (
            <Box
                height={700}
                gap={2}
                display='flex'
                flexDirection='column'
                alignItems='stretch'
            >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box component='fieldset' sx={fieldSetStyle}>
                        <legend style={legendStyle}>
                            {t('FILTERS')}
                        </legend>

                        <Stack direction='row' spacing={2}>
                            <TextField
                                size='small'
                                label={t('NAME')}
                                autoComplete='off'
                                fullWidth
                                value={name}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                                onChange={onChangeNameHandler}
                            />

                            <ShipperConsigneeDirectionSelect
                                label={t('TYPE')}
                                value={type}
                                onChange={onChangeTypeHandler}
                            />

                            <StatusSelect
                                label={t('STATUS')}
                                data={statusData}
                                value={status}
                                fullWidth
                                onChange={onChangeStatusHandler}
                            />
                        </Stack>

                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button size='small' variant='contained' onClick={onApplyHandler}>
                                <CheckCircleOutlineIcon fontSize='small' />&nbsp;{t('APPLY')}
                            </Button>
                            <Button size='small' variant='outlined' onClick={onResetHandler}>
                                <RotateLeftIcon fontSize='small' />&nbsp;{t('RESET')}
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <ShipperConsigneeGrid />
            </Box>
        );
    }, [
        name, onApplyHandler, onChangeNameHandler, onChangeStatusHandler,
        onChangeTypeHandler, onResetHandler, status, t, type
    ]);

    return (
        <BaseDialog
            open={open}
            title={t('SHIPPER & CONSIGNEE MANAGEMENT')}
            maxWidth={'xl'}
            buildContent={onBuildContent}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default ShipperConsigneeManagementDialog;