import ContactMailIcon from '@mui/icons-material/ContactMail';
import DescriptionIcon from '@mui/icons-material/Description';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationHomeUrl } from "../../../helpers/generalHelper";
import { useNotification } from "../../../hooks/useNotification";
import { useWarehouse } from "../../../hooks/useWarehouse";
import { IStepItem } from "../../../models/CommonModels";
import { IWarehouseCoverFaceResponseDto } from "../../../models/WarehouseModels";
import WarehouseService from "../../../services/WarhouseService";
import { RootState } from "../../../store/store";
import BaseStep from "../../Base/BaseStepComponent/BaseStep";
import WarehouseContactsStep from "./Contacts/WarehouseContactsStep";
import WarehouseDescriptionStep from "./Description/WarehouseDescriptionStep";
import WarehouseOverviewStep from "./Overview/WarehouseOverviewStep";
import WarehouseReviewsStep from "./Reviews/WarehouseReviewsStep";
import WarehouseStatusAndReputationStep from "./StatusAndReputation/WarehouseStatusAndReputationStep";
import WarehouseStepCoverFace from "./WarehouseStepCoverFace";

const WarehouseStep = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const navigate = useNavigate();
    const { refresh } = useSelector((state: RootState) => state.warehouseSlice.step);
    const { gridRefresh } = useWarehouse();

    const onRetrieveDataApiHandler = useCallback((id: string) => {
        return WarehouseService.fetchCoverFaceData(id);
    }, []);

    const onBuildCoverFaceHandler = useCallback((data: IWarehouseCoverFaceResponseDto): React.ReactNode => {
        return <WarehouseStepCoverFace data={data} />;
    }, []);

    const onBuildItemsHandler = useCallback((data: IWarehouseCoverFaceResponseDto): IStepItem[] => {
        const id: string = data.uuid;
        const readonly: boolean = data.readonly;
        return [{
            title: t('OVERVIEW'),
            content: <WarehouseOverviewStep warehouseId={id} readonly={readonly} />,
            icon: <FileOpenIcon />
        }, {
            title: t('CONTACTS'),
            content: <WarehouseContactsStep warehouseId={id} readonly={readonly} />,
            icon: <ContactMailIcon />
        }, {
            title: t('DESCRIPTION'),
            content: <WarehouseDescriptionStep warehouseId={id} />,
            icon: <DescriptionIcon />
        }, {
            title: t('REVIEW'),
            content: <WarehouseReviewsStep warehouseId={id} />,
            icon: <ReviewsIcon />
        }, {
            title: t('STATUS & REPUTATION'),
            content: <WarehouseStatusAndReputationStep warehouseId={id} readonly={readonly} />,
            icon: <SwapHorizontalCircleIcon />
        }];
    }, [t]);

    const onDeleteBtnClickHandler = useCallback((uuid: string) => {
        (async () => {
            const [error] = await WarehouseService.remove(uuid);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
            } else {
                const newUrl: string = normalizeNavigationHomeUrl(true);
                navigate(`/${newUrl}`);
                displayNotification({ message: t('Warehouse was successfully deleted.') });
                gridRefresh();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <BaseStep
            retrieveDataApi={onRetrieveDataApiHandler}
            refresh={refresh}
            buildCoverFace={onBuildCoverFaceHandler}
            buildItems={onBuildItemsHandler}
            onDeleteBtnClick={onDeleteBtnClickHandler}
            deleteBtnTooltip={t('Delete item')}
            closeBtnTooltip={t('Close and return to the main list')}
        />
    );
}
export default WarehouseStep;