import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, IconButton, styled, Tooltip } from "@mui/material";
import { ReactNode, useCallback } from "react";

const HiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

interface IProps {
    label?: string;
    fullHeight?: boolean;
    tooltip?: string;
    variant?: 'outlined' | 'contained',
    size?: 'small' | 'medium',
    icon?: ReactNode;
    iconBtn?: boolean;
    disabled?: boolean;
    acceptedFiles?: string;
    onSelect: (file: File) => void;
}
const FileUploadBtn = (props: IProps) => {
    const {
        label, tooltip, variant = 'contained', size = 'medium', fullHeight = false,
        icon = <CloudUploadIcon />, acceptedFiles = '*', onSelect, disabled = false,
        iconBtn = false
    } = props;

    const onSelectHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = event.target.files;
        if (files && files[0]) {
            const file: File = files[0];
            onSelect(file);
        }
    }, [onSelect]);

    const style: {} = fullHeight ? { height: '100%' } : {};

    return (
        <Tooltip title={tooltip} >
            <span>
                {iconBtn
                    ?
                    <IconButton
                        component="label"
                        size={size}
                        color='primary'
                        disabled={disabled}
                    >
                        {icon}
                        <HiddenInput
                            type='file'
                            accept={acceptedFiles}
                            onChange={onSelectHandler}
                        />
                    </IconButton>
                    :
                    <Button
                        component="label"
                        variant={variant}
                        startIcon={icon}
                        size={size}
                        disabled={disabled}
                        sx={style}
                    >
                        {label}
                        <HiddenInput
                            type='file'
                            accept={acceptedFiles}
                            onChange={onSelectHandler}
                        />
                    </Button>
                }
            </span>
        </Tooltip>
    );
}
export default FileUploadBtn;