import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomer } from '../../../hooks/useCustomer';
import { ECriteriaExpression, ICriteria, IFilter } from '../../../models/CommonModels';
import { ICustomerCoverFaceResponseDto } from '../../../models/CustomerModels';
import CustomerService from '../../../services/CustomerService';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';
import RatingView from '../../Base/RatingComponent/RatingView';
import ReputationView from '../../Base/ReputationComponent/ReputationView';

interface IProps {
    data: ICustomerCoverFaceResponseDto;
}
const CustomerStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { gridRefresh, stepRefresh } = useCustomer();

    const content = useMemo((): React.ReactNode => {
        return (
            <Box flex={1}>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
                    <ReputationView
                        value={data.reputationLevel}
                        showText={false}
                        minWidth='0px'
                        width='24px'
                        imageSize='medium'
                    />

                    <Stack direction='column' spacing={0.5}>
                        <Typography>
                            <b>{data.name}</b>
                        </Typography>

                        <Typography sx={{ fontSize: '13px', color: 'black' }}>
                            <b>{data.status.toString().replaceAll('_', ' ')}</b>
                        </Typography>
                    </Stack>

                    <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

                    <Stack direction='column' spacing={0.5}>
                        <RatingView value={data.reviewMark} />

                        <Typography sx={{ fontSize: '13px' }}>
                            <b>&nbsp;</b>
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        );
    }, [data.name, data.reputationLevel, data.reviewMark, data.status]);

    const onFavoriteBtnClickHandler = useCallback(() => {
        (async () => {
            const ids: string[] = [data.uuid];
            if (data.favorite) {
                CustomerService.removeFavorites(ids);
            } else {
                CustomerService.createFavorites(ids);
            }
            gridRefresh();
            stepRefresh();
        })();
    }, [data.favorite, data.uuid, gridRefresh, stepRefresh]);

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return CustomerService.downloadReport(filter);
    }, [data.uuid]);

    return (
        <BaseStepCoverFace
            content={content}

            favoriteBtnAddTooltip={t('Add to favorites')}
            favoriteBtnRemoveTooltip={t('Remove from favorites')}
            favoriteBtn={data.favorite}
            onFavoriteBtnClick={onFavoriteBtnClickHandler}

            downloadBtnTooltip={t('Download customer')}
            downloadApi={onDownloadApiHandler}
        />
    );
}
export default CustomerStepCoverFace;