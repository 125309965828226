import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { IFileDetailsRequestDto, IFileRequestDto } from "../../../../models/FileModel";
import GlobalPreferenceService from "../../../../services/GlobalPreferenceService";
import FileUploadDialog from "../../../Base/FileComponent/FileUploadDialog";

const formId: string = 'global-preference-logo-form';
interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const GlobalPreferenceCompanyLogoDialog = (props: IProps) => {
    const { open, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const { register, setValue, handleSubmit, formState: { isValid } } = useForm<IFileRequestDto>({
        defaultValues: { file: undefined }
    });

    const onSubmit = useCallback((data: IFileRequestDto) => {
        setLoading(true);
        (async () => {
            const dataForm = new FormData();
            dataForm.append('logo', data.file);

            const [error, response] = await GlobalPreferenceService.updateCompanyLogo(dataForm);
            if (response) {
                displayNotification({ message: t('Logo was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onCloseBtnClick, onSubmitBtnClick, t]);

    const validateFile = useCallback((value: File) => {
        return value !== undefined;
    }, []);

    register('file', { validate: validateFile });
    const onSelectHandler = useCallback((file: File, details: IFileDetailsRequestDto) => {
        setValue('file', file, { shouldValidate: true });
    }, [setValue]);

    return (
        <FileUploadDialog
            loading={loading}
            open={open}
            formId={formId}
            title={t('UPDATE LOGO')}
            acceptedFiles='image/png'
            aspect={1}
            saveBtnDisabled={!isValid}
            onSelect={onSelectHandler}
            onSubmit={handleSubmit(onSubmit)}
            onCloseBtnClick={onCloseBtnClick}
        />
    )
}
export default GlobalPreferenceCompanyLogoDialog;