import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid } from "../../models/ReduxModels";

interface SettlementState {
    grid: IReduxGrid;
};

const initialState: SettlementState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    }
};

export const SettlementSlice = createSlice({
    name: 'settlementSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: SettlementState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: SettlementState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        }
    }
});

export const SettlementActions = SettlementSlice.actions;
export const SettlementReducer = SettlementSlice.reducer;