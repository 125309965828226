import { IAddressShortResponseDto } from "./AddressModels";
import { EFrequency } from "./CommonModels";
import { EAuthority, IRoleIdNameResponseDto } from "./RoleModules";

export enum EUserStatus {
    NONE,
    ACTIVE = 'ACTIVE',
    TEMPORARY = 'TEMPORARY',
    INACTIVE = 'INACTIVE'
}

export enum EUserDocumentType {
    NONE,
    PASSPORT = 'PASSPORT',
    SOCIAL_SECURITY_NUMBER = 'SOCIAL_SECURITY_NUMBER',
    DRIVER_LICENSE = 'DRIVER_LICENSE',
    DRUG_TEST = 'DRUG_TEST',
    MEDICAL_CARD = 'MEDICAL_CARD',
    DOCUMENT = 'DOCUMENT'
}

export enum EUserPayType {
    NONE,
    RECURRING_PLUS = 'RECURRING_PLUS',
    RECURRING_MINUS = 'RECURRING_MINUS',
    ONE_TIME_DEDUCTION = 'ONE_TIME_DEDUCTION',
    ONE_TIME_BONUS = 'ONE_TIME_BONUS'
}

export enum EUserIncomeType {
    NONE,
    HOURLY = 'HOURLY',
    CENTS_PER_MILE = 'CENTS_PER_MILE',
    PERCENTAGE = 'PERCENTAGE',
    DROP_PICKUPS = 'DROP_PICKUPS'
}

export enum EUserEndorsementType {
    NONE,
    HAZMAT = 'HAZMAT',
    REGULAR_CLASS_A = 'REGULAR_CLASS_A',
    TANKER = 'TANKER',
    TWIC = 'TWIC'
}

export interface IUserResponseDto {
    uuid: string;
    fullName?: string;
    username: string;
    email: string;
    role: string;
    color: string;
    avatar?: string;
    online: boolean;
    lastActivityDate?: number;
    activeFromDate?: number;
    activeToDate?: number;
    status: EUserStatus;
    number?: number;
    userResponsible?: IUserShortResponseDto;
    favorite: boolean;
    createdBy: string;
    createdDate: number;
    updatedBy?: string;
    updatedDate?: number;
}

export interface IUserCoverFaceResponseDto {
    uuid: string;
    name: string;
    username: string;
    role: string;
    color: string;
    favorite: boolean;
    avatar?: string;
    readonly: boolean;
    online: boolean;
    activeFromDate?: number;
    activeToDate?: number;
    number?: number;
    status: EUserStatus;
}

export interface IUserRequestDto {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    roleId: string;
    color: string;
    addressId?: string;
    birthday?: number;
    number?: number;
    userResponsibleId?: string;
    endorsements?: EUserEndorsementType[];
}

export interface IUserDocumentRequestDto {
    type: EUserDocumentType;
    number?: string;
    dateOfIssue?: number;
    expirationDate?: number;
    issueCountryId?: string;
    issueStateId?: string;
    caption?: string;
}

export interface IUserStatusResponseDto {
    uuid: string;
    activeFromDate?: number;
    activeToDate?: number;
    status: EUserStatus;
}

export interface IUserStatusRequestDto {
    uuid: string;
    activeFromDate?: number;
    activeToDate?: number;
    status: EUserStatus;
}

export interface IUserOverviewResponseDto {
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
    role: IRoleIdNameResponseDto;
    color: string;
    address?: IAddressShortResponseDto;
    birthday?: number;
    number?: number;
    userResponsible?: IUserShortResponseDto;
    endorsements?: EUserEndorsementType[];
}

export interface IUserPayRequestDto {
    type: EUserPayType;
    frequency?: EFrequency;
    note: string;
    startDate: number;
    endDate?: number;
    amount: number;
}

export interface IUserPayResponseDto {
    uuid: string;
    type: EUserPayType;
    frequency?: EFrequency;
    note: string;
    startDate: number;
    endDate?: number;
    amount: number;
    createdBy: string;
    createdDate: number;
    updatedBy?: string;
    updatedDate?: number;
}

export interface IUserIncomeResponseDto {
    uuid: string;
    type: EUserIncomeType;
    amount: number;
    caption: string;
}

export interface IUserIncomeRequestDto {
    type: EUserIncomeType;
    amount: number;
    caption: string;
}

export interface IUserAutocompleteResponseDto {
    uuid: string;
    fullName: string;
    avatar?: string;
    role: EAuthority;
    color: string;
    number?: number;
    status: EUserStatus;
}

export interface IUserShortResponseDto {
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    avatar?: string;
    color?: string;
    fullName: string;
    role?: EAuthority;
    number?: number;
}

export interface IUserContactResponseDto {
    email: string;
    additionalEmail?: string;
    phone?: string;
    additionalPhone?: string;
}

export interface IUserContactRequestDto {
    additionalEmail?: string;
    phone?: string;
    additionalPhone?: string;
}

export interface IUserEmployeeRequestDto {
    startDate?: number;
    endDate?: number;
    workload?: number;
}

export interface IUserEmployeeResponseDto {
    startDate?: number;
    endDate?: number;
    workload?: number;
}