import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { decodeFromBase64 } from '../../../helpers/EncoderDecoderHelper';
import { ICriteria } from '../../../models/CommonModels';

const contentHeight = 'calc(100vh - 100px)';

interface IProps {
    onApplyFilter: (criterias: ICriteria[]) => void;
    onResetState: () => void;
    mainSection: JSX.Element;
    stepSection: JSX.Element;
}
const BasePage = (props: IProps) => {
    const { onApplyFilter, onResetState, mainSection, stepSection } = props;

    const size1600 = useMediaQuery('(min-width:1600px)');
    const size1500 = useMediaQuery('(min-width:1500px)');
    const size1350 = useMediaQuery('(min-width:1350px)');
    const size1100 = useMediaQuery('(min-width:1100px)');
    const size1000 = useMediaQuery('(min-width:1000px)');
    const size900 = useMediaQuery('(min-width:900px)');
    const size800 = useMediaQuery('(min-width:800px)');
    const size750 = useMediaQuery('(min-width:750px)');
    const size700 = useMediaQuery('(min-width:700px)');
    const size630 = useMediaQuery('(min-width:630px)');

    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [showComponent, setShowComponent] = useState<boolean>(false);
    const [stepWidth, setStepWidth] = useState<string>('100%');

    useEffect(() => {
        if (searchParams.has('filter')) {
            const filterEncoded: string = searchParams.get('filter') || '';
            const filterDecoded: string = decodeFromBase64(filterEncoded);
            const criterias: ICriteria[] = JSON.parse(filterDecoded);
            onApplyFilter(criterias);
        }

        if (size1600) {
            setStepWidth('1500px');
        } else if (size1500) {
            setStepWidth('1400px');
        } else if (size1350) {
            setStepWidth('1100px');
        } else if (size1100) {
            setStepWidth('1050px');
        } else if (size1000) {
            setStepWidth('950px');
        } else if (size900) {
            setStepWidth('850px');
        } else if (size800) {
            setStepWidth('750px');
        } else if (size750) {
            setStepWidth('710px');
        } else if (size700) {
            setStepWidth('670px');
        } else if (size630) {
            setStepWidth('620px');
        }

        setShowComponent(true);
        return () => onResetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ width: '100%', height: contentHeight, maxHeight: contentHeight }}>
            {showComponent &&
                <Box height='100%'>
                    {mainSection}

                    {id &&
                        <Drawer anchor='right' open={id !== undefined}>
                            <Box width={stepWidth}>
                                {stepSection}
                            </Box>
                        </Drawer>
                    }
                </Box>
            }
        </Box >
    );
}
export default BasePage;