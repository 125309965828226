import { buildGoogleMapsLink } from "../../../helpers/AddressHelper";
import { ICustomerResponseDto } from "../../../models/CustomerModels";
import LinkNav from "../../Base/LinkComponent/LinkNav";

interface IProps {
    data: ICustomerResponseDto;
}
const CustomerMapsMarker = (props: IProps) => {
    const { data } = props;
    const addressLink: string = buildGoogleMapsLink(data.address.latitude, data.address.longitude);

    return (
        <div>
            <LinkNav
                label={data.address.fullAddress}
                url={addressLink}
                rel='noreferrer'
            />
        </div>
    );
}
export default CustomerMapsMarker;