import CategoryIcon from '@mui/icons-material/Category';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../../helpers/NumberHelper';
import { useLoad } from '../../../hooks/useLoad';
import { ECriteriaExpression, ICriteria, IFilter } from '../../../models/CommonModels';
import { ILoadCoverFaceResponseDto } from '../../../models/LoadModels';
import LoadService from '../../../services/LoadService';
import { RootState } from '../../../store/store';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';
import LinkNav from '../../Base/LinkComponent/LinkNav';

const truncateString = (value: string): string => {
    const length: number = 20;
    return value.length > length ? value.slice(0, length) + "..." : value;
};

interface IProps {
    data: ILoadCoverFaceResponseDto;
}
const LoadStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { gridRefresh, stepRefresh } = useLoad();

    const content = useMemo((): React.ReactNode => {
        const style: {} = data.chargesRequiresProofs ? {} : { color: 'red' };
        return (
            <Box flex={1}>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
                    <CategoryIcon style={{ color: 'rgb(106, 104, 104)' }} />

                    <Stack direction='column' spacing={0.5}>
                        <Typography>
                            <b>#{data.idno}</b>
                        </Typography>

                        <Typography sx={{ fontSize: '13px', color: 'black' }}>
                            <b>{data.status.toString().replaceAll('_', ' ')}</b>
                        </Typography>
                    </Stack>

                    <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

                    <Stack direction='column' spacing={0.5}>
                        <Typography>
                            <LinkNav
                                tooltip={data.customer.name.toUpperCase()}
                                label={truncateString(data.customer.name.toUpperCase())}
                                url={`/customers/${data.customer.uuid}`}
                            />
                        </Typography>

                        <Typography>
                            {!data.assignLater && data.driver
                                ?
                                <LinkNav
                                    label={data.driver.fullName.toUpperCase()}
                                    url={`/users/${data.driver.uuid}`}
                                />
                                :
                                data.assignLater
                                    ? <span style={{ color: 'rgb(198, 40, 40)' }}>{t('ASSIGN LATER')}</span>
                                    : <b>&nbsp;</b>
                            }
                        </Typography>
                    </Stack>

                    <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

                    <Stack direction='column' spacing={0.5}>
                        <Typography>
                            <b>{`${t('RATE')}:`} {currencyFormat(data.rate)}</b>
                        </Typography>

                        <Typography>
                            <b>{`${t('FLAT RATE')}:`} {currencyFormat(data.flatRate || 0)}</b>
                        </Typography>
                    </Stack>

                    <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

                    <Stack direction='column' spacing={0.5}>
                        <Typography>
                            <b>{`${t('O. CHARGES')}:`} <span style={style}>{currencyFormat(data.charges)}</span></b>
                        </Typography>

                        <Typography>
                            <b>{`${t('TOTAL RATE')}:`} {currencyFormat(data.totalRate)}</b>
                        </Typography>
                    </Stack>

                    <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

                    <Stack direction='column' spacing={0.5}>
                        <Typography>
                            <b>{t('SHP WT:')} {data.shipperWeight} {mass}</b>
                        </Typography>

                        <Typography>
                            <b>{t('CNE WT:')} {data.consigneeWeight} {mass}</b>
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        );
    }, [
        data.assignLater, data.charges, data.chargesRequiresProofs,
        data.consigneeWeight, data.customer.name, data.customer.uuid,
        data.driver, data.flatRate, data.idno, data.rate, data.shipperWeight,
        data.status, data.totalRate, mass, t
    ]);

    const onFavoriteBtnClickHandler = useCallback(() => {
        (async () => {
            const ids: string[] = [data.uuid];
            if (data.favorite) {
                LoadService.removeFavorites(ids);
            } else {
                LoadService.createFavorites(ids);
            }
            gridRefresh();
            stepRefresh();
        })();
    }, [data.favorite, data.uuid, gridRefresh, stepRefresh]);

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return LoadService.downloadReport(filter);
    }, [data.uuid]);

    return (
        <BaseStepCoverFace
            content={content}

            favoriteBtnAddTooltip={t('Add to favorites')}
            favoriteBtnRemoveTooltip={t('Remove from favorites')}
            favoriteBtn={data.favorite}
            onFavoriteBtnClick={onFavoriteBtnClickHandler}

            downloadBtnTooltip={t('Download load')}
            downloadApi={onDownloadApiHandler}
        />
    );
}
export default LoadStepCoverFace;